import React, { Component } from 'react';
import './style.scss';

export default class SwitchFilter extends Component {
    constructor(state){
        super(state);
        this.onChangeFilter = this.onChangeFilter.bind(this);
    }
    onChangeFilter(value, group) {
        this.props.action(value, group)
    }
    render() {
        const {switchData, group, prefixId, checked} = this.props;
        return (
            <ul className="ts-filter__list">
                {switchData && switchData.map((item, index) => {
                    return <li className="ts-filter__item" key={index}>
                        <input
                            type="radio"
                            name={group}
                            className="ts-filter__radiobutton"
                            id={`${prefixId}-${item.value}`}
                            defaultChecked={item.value == checked ? true : false}
                            onChange={this.onChangeFilter.bind(this, `${item.value}`, `${group}`)}
                        />
                        <label className="ts-filter__switch-label" htmlFor={`${prefixId}-${item.value}`}>{item.title}</label>
                    </li>
                })}
            </ul>
        )
    }
}