import React, { Component, Fragment } from 'react';
import './style.scss';

import { Scrollbars } from 'react-custom-scrollbars';

export default class Dropdown extends Component {
    constructor(state) {
        super(state);
        this.state = {
            dropdownActive: false,
            label: this.props.orderByLabel
        };
        this.toggle = this.toggle.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
    }
    toggle(e) {
        e.preventDefault();
        this.setState({ dropdownActive: !this.state.dropdownActive });
    }

    handleClick() {
        if (!this.state.dropdownActive) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState({
            dropdownActive: !this.state.dropdownActive
        })
    }

    handleOutsideClick(e) {
        if (this.node.contains(e.target)) {
            return;
        }

        this.handleClick();
    }
    onChangeFilter(value, label) {
        this.setState({ dropdownActive: !this.state.dropdownActive });
        document.removeEventListener('click', this.handleOutsideClick, false);
        this.props.action(value, label)
    }
    render() {
        const { filtersList, orderByLabel } = this.props;
        const filtersListCopy = filtersList ? [{ ...filtersList }] : null;
        const onChangeFilter = this.onChangeFilter;

        return (
            <Fragment>
                <div className={this.state.dropdownActive ? "ts-dropdown open" : "ts-dropdown"} ref={node => {
                    this.node = node;
                }}>
                    <div className="ts-dropdown__header" onClick={this.handleClick}>
                        <span className="ts-dropdown__input">
                            {orderByLabel}
                        </span>
                        <span className="ts-dropdown__arrow"></span>
                    </div>
                    <div className="ts-dropdown__menu">
                        <Scrollbars autoHeight autoHeightMax={300}>
                            <ul className="ts-dropdown__list">
                                {filtersListCopy && filtersListCopy.map(function (row) {
                                    return Object.values(row).map(function (cell, k) {
                                        return <li
                                            className="ts-dropdown__list-item with-hover"
                                            onClick={onChangeFilter.bind(this, `${cell.id}`, `${cell.value}`)}
                                            key={k}>
                                            <span className="ts-dropdown__data">{cell.value}</span>
                                        </li>
                                    })
                                })}
                            </ul>
                        </Scrollbars>
                    </div>
                </div>
            </Fragment>)
    }
}