import React, { Component } from 'react';
import './style.scss';
import LineChart from 'components/main/line-chart';


export default class EthnicPopulation extends Component {

    render() {
        const {metroId, marketConsumer} = this.props;
        const chartData = marketConsumer && marketConsumer.ethnic_population;
        return (
            <div className="ts-block__content-modul border-bottom clearfix">
                <div className="ts-mod-header">ETHNIC POPULATION</div>

                <LineChart
                    metroId={metroId}
                    chartData={chartData}
                    total={true}
                />

            </div>
        )
    }
}