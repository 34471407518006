export const METRO_NAME_GET_DATA = "metro/NAME_GET_DATA";
export const METRO_GET_DATA = "metro/GET_DATA";
export const METRO_UPDATE_DATA = "metro/UPDATE_DATA";
export const METRO_GET_TAB = "metro/GET_TAB";
export const METRO_UPDATE_TAB = "metro/UPDATE_TAB";
export const METRO_CUISINES_GET_DATA = "metro/CUISINES_GET_DATA";
export const METRO_CONSUMER_GET_DATA = "metro/CONSUMER_GET_DATA";
export const METRO_CUISINES_UPDATE_DATA = "metro/CUISINES_UPDATE_DATA";
export const METRO_CHAINS_AREA_GET_DATA = "metro/CHAINS_AREA_GET_DATA";
export const METRO_CHAINS_AREA_UPDATE_DATA = "metro/CHAINS_AREA_UPDATE_DATA";
export const METRO_CHAINS_AREA_SEGMENTS_GET_DATA = "metro/CHAINS_AREA_SEGMENTS_GET_DATA";
export const METRO_CHAINS_AREA_RESTAURANT_CUISINES_GET_DATA = "metro/CHAINS_AREA_RESTAURANT_CUISINES_GET_DATA";

export const METRO_MAJOR_ON_SITE_GET_DATA = "metro/MAJOR_ON_SITE_GET_DATA";
export const METRO_MAJOR_ON_SITE_UPDATE_DATA = "metro/MAJOR_ON_SITE_UPDATE_DATA";
export const METRO_SCOHOL_DROPDOWN_GET_DATA = "metro/SCOHOL_DROPDOWN_GET_DATA";