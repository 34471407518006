import React, { Component, Fragment, PropTypes } from 'react';
import { connect, match } from 'react-redux';

import { getAccess } from 'containers/Landing/landingActions';

import { getData } from './marketsActions';

import Header from 'components/main/header';

import GeoFilter from 'components/markets/geo-filter';


class Markets extends Component {

    constructor(state) {
        super(state);
    }

    componentDidMount() {
        document.title = 'Markets | Frosty Acres Brands';
        this.props.getData();
        this.props.getAccess();
    }

    render() {
        const path = this.props.match.path;
        return (
        <Fragment>
            <div className="wrap">
                <Header
                    path={path}
                    accessSnap={this.props.access.accessSnap}
                    accessNafem={this.props.access.accessNafem}
                    pageTitle="Markets"
                />
                <div className="container main-container">
                    <GeoFilter
                        metroAreas={this.props.metroAreas}
                    />
                </div>
            </div>
        </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    metroAreas: state.marketspageReducer.metroAreas,
    access: state.landingpageReducer.access,
});

const mapDispatchToProps = dispatch => ({
    getData: () => dispatch(getData()),
    getAccess: () => dispatch(getAccess()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Markets);