import React, { Component, Fragment, PropTypes } from 'react';
import ReactDOM from 'react-dom';
import { connect, match } from 'react-redux';

import {
    getOverviewData,
    updateMarketCuisinesData
} from 'containers/Metro/metroActions';

import AtAGlance from 'components/metro/overview/at-a-glance';
import MarketStructure from 'components/metro/overview/market-structure';
import IndexingCuisines from 'components/metro/overview/indexing-cuisines';
import MenuTypes from 'components/metro/overview/menu-types';

class OverviewIndex extends Component {
    constructor(state) {
        super(state);
        this.state = {

        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {metroCurrentId, metroId, type, regionType} = this.props;
        return (
            <Fragment>
                <AtAGlance
                    marketAnalysis={metroCurrentId === metroId ? this.props.overview.market_analysis : null}
                    type={type}
                />
                {type !== 'total_us' &&
                <IndexingCuisines
                    marketAnalysis={metroCurrentId === metroId ? this.props.overview.market_analysis : null}
                    type={type}
                />
                }
                <MarketStructure
                    marketStructure={metroCurrentId === metroId ? this.props.overview.market_structure : null}
                    //mapInitialBorders={metroCurrentId === metroId ? this.props.mapInitialBorders : null}
                    metroId={metroId}
                    type={type}
                    regionType={regionType}
                />
                {this.props.access.accessFirefly && <MenuTypes
                    metroId={metroId}
                    metroCurrentId={metroCurrentId}
                    type={type}
                />}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    access: state.landingpageReducer.access,
    metroCurrentId: state.metropageReducer.metroCurrentId,
    overview: state.metropageReducer.overview,
});

const mapDispatchToProps = dispatch => ({
    //getMapBorders: (propId) => dispatch(getMapBorders(propId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OverviewIndex);