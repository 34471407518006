import {
    MARKETS_GET_DATA
} from './marketsActionTypes';
import Http from 'helpers/http';
import { API_URL } from "../../core/constants";

export function getData() {
    return (dispatch) => {
        Http.get(`${API_URL}/markets/default/metros`).then((data) => {
            dispatch(storeData(data));
        });
    };
}

function storeData(data) {
    return {
        type: MARKETS_GET_DATA,
        data
    }
}