import React, { Component, Fragment, PropTypes } from 'react';
import { connect, match } from 'react-redux';

import MajorOnSite from 'components/metro/on-site/major-on-site';

export default class OnSiteIndex extends Component {
    constructor(state) {
        super(state);
        this.state = {};
        this.actionModal = this.actionModal.bind(this);
    }
    actionModal(id) {
        this.props.changeActiveTab(id)
    }
    render() {
        return (
            <Fragment>
                <MajorOnSite
                    metroId={this.props.metroId}
                    actionModal={this.actionModal}
                    tabId={this.props.tabId}
                    type={this.props.type}
                />
            </Fragment>
        )
    }
}