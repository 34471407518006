import React, { Component } from 'react';
import './style.scss';

export default class BornChart extends Component {

    render() {
        const bornChartCopy = this.props.marketConsumer && this.props.marketConsumer.born;
        const bornChartInCountry = this.props.marketConsumer && bornChartCopy.in_country;
        const bornChartForeign = this.props.marketConsumer && bornChartCopy.foreign;
        const bornChartForeignWidth = bornChartForeign > 0 ? bornChartForeign < 10 ? 10 : bornChartForeign : 0;
        const bornChartInCountryWidth = bornChartForeign > 0 ? bornChartForeign < 10 ? 90 : bornChartInCountry : bornChartInCountry;
        return (
            <div className="ts-block__content-modul border-bottom">
                <div className="total-population total-born-us clearfix">
                    <div className="total-born-us__col total-born-us__col-l">
                        <div className="total-population__name">Born<br/>in the US</div>
                    </div>
                    <div className="total-born-us__col-chart chart-born-us">
                        <div className="chart-born-us__progress-block">
                            <span
                                className="chart-born-us__val chart-born-us__val--left"
                                style={{width: bornChartInCountryWidth + '%'}}
                            >{bornChartInCountry}%</span>
                            <span
                                className="chart-born-us__val chart-born-us__val--right"
                                style={{width: bornChartForeignWidth + '%'}}
                            >
                                {bornChartForeign}%
                            </span>
                            <div className="chart-born-us__progress" style={{width: bornChartInCountryWidth + '%'}}></div>
                        </div>
                    </div>
                    <div className="total-born-us__col total-born-us__col-r">
                        <div className="total-population__name">Foreign<br/>born</div>
                    </div>
                </div>
            </div>
        )
    }
}