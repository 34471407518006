import React, { Component } from 'react';
import './style.scss';
import { PieChart, Pie, Cell, Sector } from 'recharts';

export default class TotalPopulation extends Component {

    render() {
        const totalPopulationCopy = this.props.marketConsumer && this.props.marketConsumer.totals;
        const totalPopulationCount = this.props.marketConsumer && totalPopulationCopy.population;
        const totalPopulationMale = this.props.marketConsumer && totalPopulationCopy.male;
        const totalPopulationFemale = this.props.marketConsumer && totalPopulationCopy.female;

        const pieChartData = [
                {name: 'Female', value: this.props.marketConsumer && totalPopulationFemale.percent, 'color': '#F4559E'},
                {name: 'Male', value: this.props.marketConsumer && totalPopulationMale.percent, 'color': '#1C6B95'},
        ];
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
            const x  = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy  + radius * Math.sin(-midAngle * RADIAN);
            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        };
        function size(pixel) {
            return (pixel / 1920) * window.innerWidth;
        }
        return (
            <div className="ts-block__content-modul border-bottom">
                <div className="ts-mod-header">
                    TOTAL POPULATION
                    <div className="ts-mod-header-light__total">{totalPopulationCount}</div>
                    <div className="ts-mod-header__subheader">Consumers</div>
                </div>
                <div className="total-population total-population--table">
                    <div className="total-population__col total-population__col--left">
                        <div className="total-population__data total-population__data--male">{this.props.marketConsumer && totalPopulationMale.count}</div>

                        <div className="total-population__name">Male</div>
                    </div>
                    <div className="total-population__col">
                        <div className="pie-chart__pie">
                            <PieChart width={142} height={142}>
                                <Pie
                                    data={pieChartData}
                                    cx={66}
                                    cy={66}
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={71}
                                    innerRadius={25}
                                    startAngle={90}
                                    endAngle={-270}
                                    fill="#82ca9d"
                                    dataKey="value"
                                    isAnimationActive={false}
                                >
                                    {
                                        pieChartData.map((entry, index) => <Cell fill={entry.color} key={index}/>)
                                    }
                                </Pie>
                            </PieChart>
                        </div>
                    </div>
                    <div className="total-population__col">
                        <div className="total-population__data total-population__data--female">{this.props.marketConsumer && totalPopulationFemale.count}</div>

                        <div className="total-population__name">Female</div>
                    </div>

                </div>
            </div>
        )
    }
}