import React, { Component } from 'react';
import './style.scss';


export default class MedianEmployed extends Component {

    render() {
        const {metroId, marketConsumer} = this.props;
        const medianData = marketConsumer && marketConsumer.median_income;
        const employedData = marketConsumer && marketConsumer.employed;
        return (
            <div className="ts-block__content-modul">

                <div className="median">
                    <div className="median__col">
                        <div className="sticker">
                            <div className="sticker__count">${marketConsumer && medianData.value}</div>
                            <div className="sticker__name">Median HH Income</div>
                            {metroId !== '0' &&
                            <div>
                                <div className="sticker__caption">national avg.</div>
                                <div className="sticker__caption-count">${marketConsumer && medianData.total_value}</div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="median__col">
                        <div className="sticker">
                            <div className="sticker__count">{marketConsumer && employedData.value}%</div>
                            <div className="sticker__name">Employed</div>
                            {metroId !== '0' &&
                            <div>
                                <div className="sticker__caption">national avg.</div>
                                <div className="sticker__caption-count">{marketConsumer && employedData.total_value}%</div>
                            </div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}