import {
    METRO_NAME_GET_DATA,
    METRO_GET_DATA,
    METRO_UPDATE_DATA,
    METRO_GET_TAB,
    METRO_UPDATE_TAB,
    METRO_CUISINES_GET_DATA,
    METRO_CONSUMER_GET_DATA,
    METRO_CUISINES_UPDATE_DATA,
    METRO_CHAINS_AREA_GET_DATA,
    METRO_CHAINS_AREA_UPDATE_DATA,
    METRO_CHAINS_AREA_SEGMENTS_GET_DATA,
    METRO_CHAINS_AREA_RESTAURANT_CUISINES_GET_DATA,

    METRO_MAJOR_ON_SITE_GET_DATA,
    METRO_MAJOR_ON_SITE_UPDATE_DATA,
    METRO_SCOHOL_DROPDOWN_GET_DATA
} from './metroActionTypes';

const defaultMetroState = {
    tabId: null,
    metroCurrentId: null,
    overview: {},
    marketCuisines: {},
    consumer_snapshot: {},
    chainsArea: {},
    chainsAreaSegments: [],
    chainsRestaurantCuisines: [],
    majorOnSite: {},
    schoolDropdown: []
};

export function metroHeaderReducer(state = defaultMetroState, action) {
    switch (action.type) {
        case METRO_NAME_GET_DATA: {
            return {
                ...state,
                metroName: action.data,
            }
        }
        case METRO_GET_DATA: {
            return {
                ...state,
                overview: action.data,
                metroCurrentId: action.id
            }
        }
        case METRO_UPDATE_DATA: {
            return {
                ...state
            }
        }

        case METRO_GET_TAB: {
            return {
                ...state,
                tabId: action.tabId
            }
        }
        case METRO_UPDATE_TAB: {
            return {
                ...state,
                tabId: action.tabId
            }
        }
        default: {
            return state;
        }
    }
}

export function metroOverviewReducer(state = defaultMetroState, action) {
    switch (action.type) {
        case METRO_CUISINES_GET_DATA: {
            return {
                ...state,
                marketCuisines: {
                    ...action.data,
                }
            }
        }
        case METRO_CUISINES_UPDATE_DATA: {
            return {
                ...state,
                marketCuisines: {
                    ...action.data,
                }
            }
        }

        default: {
            return state;
        }
    }
}

export function metroOperatorReducer(state = defaultMetroState, action) {
    switch (action.type) {
        case METRO_CHAINS_AREA_GET_DATA: {
            return {
                ...state,
                chainsArea: {
                    ...action.data,
                }
            }
        }
        case METRO_CHAINS_AREA_UPDATE_DATA: {
            return {
                ...state,
                chainsArea: {
                    ...action.data,
                }
            }
        }
        case METRO_MAJOR_ON_SITE_GET_DATA: {
            return {
                ...state,
                majorOnSite: {
                    ...action.data,
                }
            }
        }
        case METRO_MAJOR_ON_SITE_UPDATE_DATA: {
            return {
                ...state,
                majorOnSite: {
                    ...action.data,
                }
            }
        }
        case METRO_SCOHOL_DROPDOWN_GET_DATA: {
            return {
                ...state,
                schoolDropdown: {
                    ...action.data,
                }
            }
        }
        case METRO_CHAINS_AREA_SEGMENTS_GET_DATA: {
            return {
                ...state,
                chainsAreaSegments: {
                    ...action.data,
                }
            }
        }
        case METRO_CHAINS_AREA_RESTAURANT_CUISINES_GET_DATA: {
            return {
                ...state,
                chainsRestaurantCuisines: {
                    ...action.data,
                }
            }
        }

        default: {
            return state;
        }
    }
}

export function metroConsumerReducer(state = defaultMetroState, action) {
    switch (action.type) {
        case METRO_CONSUMER_GET_DATA: {
            return {
                ...state,
                consumer_snapshot: {
                    ...action.data,
                }
            }
        }

        default: {
            return state;
        }
    }
}
