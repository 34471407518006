import React, { Component, Fragment, PropTypes } from 'react';
import { connect, match } from 'react-redux';

import { getAccess } from 'containers/Landing/landingActions';
import {
    getDataMetroGeneral,
    getDataMetro,
    getTab,
    updateTab,
    getConsumerData,
} from './metroActions';
import { getData } from 'containers/Markets/marketsActions';

import Header from 'components/main/header';
import OverviewIndex from 'components/metro/overview/overview-index';
import OperatorsIndex from 'components/metro/operators/operators-index';
import ConsumerIndex from 'components/metro/consumer/consumer-index';
import OnSiteIndex from 'components/metro/on-site/on-site-index';
import ModalAccess from 'components/main/modal-access';
import GeoFilter from 'components/markets/geo-filter';

import Icon from 'components/main/svg-component';

class Metro extends Component {
    constructor(state) {
        super(state);
        this.state = {
            activeTabId: 0,
            metroTitle: '',
            segmentsListVisible: false,
            //modal access
            modalVisible: false,
            popupTargetName: '',
            popupTarget: '',
            popupType: '',
            headerSticky: false
        };
        this.changeActiveTab = this.changeActiveTab.bind(this);
        this.clickLink = this.clickLink.bind(this);
        this.successModal = this.successModal.bind(this);
        this.showSegmentList = this.showSegmentList.bind(this);
        this.segmentsClose = this.segmentsClose.bind(this);
    }

    componentDidMount() {
        this.props.getTab(this.state.activeTabId);
        const id = this.props.match.params.id;
        const type = this.props.match.params.type === 'census' ? 'census_region_id' : this.props.match.params.type;
        this.setState({id: id});
        this.props.getAccess();
        this.props.getDataMetroGeneral(id);
        this.props.getDataMetro(id, type);
        this.props.getData();
        this.props.getConsumerData(id, type);
        window.scrollTo(0, 0);
    }
    componentDidUpdate(prevProps) {
        const id = this.props.match.params.id;
        const prevChainId = prevProps.match.params.id;
        const currentChainId = this.props.match.params.id;
        const type = this.props.match.params.type;
        if (prevChainId !== currentChainId) {
            this.setState({
                id: id,
                segmentsListVisible: false
            });
            this.props.getDataMetroGeneral(id);
            this.props.getDataMetro(id, type);
            this.props.getConsumerData(id, type);
            window.scrollTo(0, 0);
        }
    }

    windowScroll() {
        const scrollPosition = window.scrollY;
        if(scrollPosition >= 4) {
            this.setState({headerSticky: true});
        } else {
            this.setState({headerSticky: false});
        }
    }

    changeActiveTab(id) {
        this.setState({activeTabId: id});
        this.props.updateTab(id);
        window.scrollTo(0, 0);
    }

    clickLink(type, target, name) {
        this.setState({
            modalVisible: true,
            popupTargetName: name,
            popupTarget: target,
            popupType: type,
        });
    }
    successModal() {
        this.setState({
            modalVisible: false,
            popupTargetName: '',
            popupTarget: '',
            popupType: '',
        });
    }
    showSegmentList() {
        this.setState({
            segmentsListVisible: !this.state.segmentsListVisible
        });
    }
    segmentsClose() {
        this.setState({
            segmentsListVisible: false
        });
    }

    renderTabs() {
        switch (this.props.tabId) {
            case 0: {
                return (
                    <Fragment>
                        <OverviewIndex
                            metroId={this.props.match.params.id}
                            type = {this.props.match.params.type === 'census' ? 'census_region_id' : this.props.match.params.type}
                            regionType={this.props.match.params.type}
                        />
                    </Fragment>
                )
            }
            case 1: {
                return (
                    <Fragment>
                        <OperatorsIndex
                            metroId={this.props.match.params.id}
                            changeActiveTab={this.changeActiveTab}
                            tabId={this.props.tabId}
                            type = {this.props.match.params.type === 'census' ? 'census_region_id' : this.props.match.params.type}
                        />
                    </Fragment>
                )
            }
            case 2: {
                return (
                    <OnSiteIndex
                        metroId={this.props.match.params.id}
                        changeActiveTab={this.changeActiveTab}
                        tabId={this.props.tabId}
                        type = {this.props.match.params.type === 'census' ? 'census_region_id' : this.props.match.params.type}
                    />
                )
            }
            case 3: {
                return (
                    <ConsumerIndex
                        metroId={this.props.match.params.id}
                        marketConsumer={this.props.marketConsumer}
                        type = {this.props.match.params.type === 'census' ? 'census_region_id' : this.props.match.params.type}
                    />
                )
            }
        }
    }

    render() {
        const {metroCurrentId} = this.props;
        const titleMetro = metroCurrentId === this.props.match.params.id ? this.props.overview.title : '';
        const titleMetroPage = this.props.metroName
            ? this.props.metroName.name + ' | '
            : '';
        const path = this.props.match.path;
        document.title = titleMetroPage + 'Frosty Acres Brands';
        return (
        <Fragment>
            <div className="wrap">
                <Header
                    path={path}
                    accessSnap={this.props.access.accessSnap}
                    accessNafem={this.props.access.accessNafem}
                    pageTitle={this.props.match.params.type === 'census' ? 'Census region' : 'Markets'}
                />

                <div className={"main-title" + (this.state.headerSticky ? ' is-sticky': '')}>
                    <div className="main-title__inner main-title__inner--c-start">
                        <div className="main-title__text">{titleMetro}</div>
                        <div className="main-title__action-container">
                            <div
                                className="main-title__action"
                                onClick={this.showSegmentList.bind(this)}
                                //style={{display: 'none'}}
                            >
                                select Metro Area
                            </div>
                        </div>
                        <div className="main-title__navigation">
                            <ul className="tabs">
                                <li className={(0 === this.props.tabId ? ' tabs__item is-active' : ' tabs__item')}
                                    onClick={this.changeActiveTab.bind(this, 0)}
                                >Overview</li>
                                <div className="tabs__items-row">
                                    <div className="tabs__items-row-inner">
                                        <li className={(1 === this.props.tabId ? ' tabs__item is-active' : ' tabs__item')}
                                            onClick={this.changeActiveTab.bind(this, 1)}
                                        >Top Chains</li>
                                        <li className={(2 === this.props.tabId ? ' tabs__item is-active' : ' tabs__item')}
                                            onClick={this.changeActiveTab.bind(this, 2)}
                                        >On-Site</li>
                                    </div>
                                    <div className="tabs__item-hint"><span className="tabs__item-hint-text">requires Datassential Firefly</span></div>
                                </div>
                                <li className={(3 === this.props.tabId ? ' tabs__item is-active' : ' tabs__item')}
                                    onClick={this.changeActiveTab.bind(this, 3)}
                                >Consumers</li>
                            </ul>
                        </div>
                    </div>
                    <div className="modal-helper container">
                        {this.state.segmentsListVisible &&
                        <div className={'segments' + (this.state.metroAreas !== null ? ' is-modal' : '')}>
                            <span className="segments__close-button" onClick={this.segmentsClose.bind(this)}>
                                <Icon iconClassName='remove'/>
                            </span>
                            <GeoFilter
                                metroAreas={this.props.metroAreas}
                                metroCurrentId={metroCurrentId}
                            />
                        </div>
                        }
                    </div>
                </div>

                <div className={"container container--metro main-container" + (this.state.headerSticky ? ' is-head-sticky': '')}>
                    {this.renderTabs()}
                </div>

                <ModalAccess
                    popupTargetName={this.state.popupTargetName}
                    popupTarget={this.state.popupTarget}
                    popupType={this.state.popupType}
                    modalVisible={this.state.modalVisible}
                    successModal={this.successModal.bind(this)}
                />
            </div>
        </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    access: state.landingpageReducer.access,
    metroName: state.metropageReducer.metroName,
    metroCurrentId: state.metropageReducer.metroCurrentId,
    overview: state.metropageReducer.overview,
    tabId: state.metropageReducer.tabId,
    marketConsumer: state.metroConsumerReducer.consumer_snapshot,
    metroAreas: state.marketspageReducer.metroAreas,
});

const mapDispatchToProps = dispatch => ({
    getAccess: () => dispatch(getAccess()),
    getData: () => dispatch(getData()),
    getDataMetroGeneral: (propId) => dispatch(getDataMetroGeneral(propId)),
    getDataMetro: (propId, type) => dispatch(getDataMetro(propId, type)),
    getConsumerData: (propId, type) => dispatch(getConsumerData(propId, type)),
    getTab: (tabId) => dispatch(getTab(tabId)),
    updateTab: tabId => dispatch(updateTab(tabId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Metro);
