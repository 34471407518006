import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import './style.scss';
import { Scrollbars } from 'react-custom-scrollbars';


export default class GeoFilter extends Component {
    constructor(state) {
        super(state);
        this.state = {
            searchVal: '',
            activeTabId: 0,
            regionsTab: [
                {
                    name: 'Select a market',
                    id: 0
                },
                {
                    name: 'Census Regions',
                    id: 1
                }
            ]
        };
        this.bySearch = this.bySearch.bind(this);
        this.changeActiveTab = this.changeActiveTab.bind(this);
    }
    bySearch(event) {
        this.setState({
            searchVal: event.target.value
        });
    }
    changeActiveTab(id) {
        this.setState({activeTabId: id});
    }

    render() {
        const _this = this;
        const metroAreasCopy = [...this.props.metroAreas];
        const activeTabId = _this.state.activeTabId;
        const metroCurrentId = this.props.metroCurrentId;

        const scrollH = window.outerHeight - 277;

        const filterData = metroAreasCopy.map((res, i) => {
            return res.filter(function(obj) {
                const value = _this.state.searchVal;
                const regex = new RegExp('^' + value, 'gi');
                if(regex.test(obj.name)) {
                    return obj.name;
                }
            })
        });

        const metroList = filterData.map((result, i) => {
            const type = 'metro';
            return <div className="geo-filter__area-set" key={i}>
                {
                    result.map((item, k) => {
                        return <div className="geo-filter__item-outer" key={k}>
                            <Link
                                to={`/markets/${item.id}-${type}`}
                                className={'geo-filter__item' + (metroCurrentId === item.id ? ' is-checked' : '')}
                            ><span className='geo-filter__item-title'>{item.name}</span></Link>
                        </div>
                    })
                }
            </div>
        });

        const isIE = /*@cc_on!@*/false || !!document.documentMode;

        return (
            <div className="geo-filter-holder">
                <div className="geo-filter">
                    <div className="geo-filter__header">
                        <div className="geo-filter__tabs">
                            <div className="geo-tabs">
                                <div
                                    className={"geo-tabs__item is-active is-no-link"}
                                >Select a market</div>
                            </div>
                        </div>
                        <input type="text"
                               onChange={this.bySearch}
                               placeholder="Search Market"
                               className="geo-filter__search"
                        />

                        <Link to={`/markets/0-total_us`} className="geo-filter__total-reset">Total US</Link>
                    </div>
                    <div className={"geo-filter__area-wrapp" + (isIE ? ' is-ie' : '')}>
                        <Scrollbars
                            autoHeight
                            autoHeightMax={`calc(100vh - 277px)`}
                        >
                            <div className="geo-filter__area-cols-wrapp">
                                {metroList}
                            </div>
                        </Scrollbars>
                    </div>
                </div>
            </div>
        )
    }
}