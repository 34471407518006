import React, { Component } from 'react';
import './style.scss';

export default class PaginationTable extends Component {

    render() {
        const {action, tablePagination, activePagechainArea}  = this.props;
        var list = [];

        for (let i = 0; i < tablePagination; i++) {
            const page = i + 1;
            let start;
            let end;
            const pageVisible = 9;
            if(tablePagination <= pageVisible) {
                start = 1;
                end = pageVisible;
            } else {
                start = activePagechainArea - 4;
                end = activePagechainArea + 4;
                if (start < 1) {
                    end += (1 - start);
                    start = 1;
                } else if (end > tablePagination) {
                    start -= (end - tablePagination);
                    end = tablePagination;
                }
            }
            list.push(<li key={i}
                          onClick={action.bind(this, page)}
                          className={
                              (((page >= start) && (page <= end)) ? '' : 'hidden')
                              + (page === activePagechainArea ? 'active' : '')}
            >{i + 1}</li>);
            //console.log('start ' + start)
            //console.log('end ' + end)
        }

        return (
            <ul className="ts-pagination clearfix">
                {tablePagination > 1 &&
                <li className={'ts-pagination__item last' + (activePagechainArea === 1 ? ' disabled' : '')}
                    onClick={action.bind(this, 1)}
                >first</li>
                }
                {list}
                {tablePagination > 1 &&
                <li className={'ts-pagination__item last' + (activePagechainArea === tablePagination ? ' disabled' : '')}
                    onClick={action.bind(this, tablePagination)}
                >last</li>
                }
            </ul>
        )
    }
}