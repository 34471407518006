import React, { Component, Fragment } from 'react';
import './style.scss';

import { BarChart, Bar, XAxis, ResponsiveContainer, Cell, LabelList } from 'recharts';

export default class ShareOperators extends Component {
    constructor(state) {
        super(state);
        this.state = {};
    }
    render() {
        const {shareOfOperatorSpend} = this.props;
        const resultShareOfOperatorSpendChart = [];
        const segmentId = this.props.segmentId;

        {if(shareOfOperatorSpend && shareOfOperatorSpend !== '') {
            shareOfOperatorSpend.map((item, index) => {
                const data = {
                    name: item.title,
                    point: item.height,
                    value: item.value,
                    activeSegment: item.id == segmentId ? true : false
                };
                resultShareOfOperatorSpendChart.push(data);
            });
        }};
        function CustomizedAxisTick(props) {
            const {x, y, stroke, payload} = props;

            return (
                <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={6} textAnchor="end" fontSize={14} fill="#000" transform="rotate(-90)">{payload.value}</text>
                </g>
            );
        }

        function renderCustomizedLabel(props) {
            const { x, y, width, value, fill } = props;
            return (
                <g>
                    <text
                        x={x + width / 2}
                        y={y - 7}
                        fill={fill === '#56C734' ? '#000' : 'transparent'}
                        textAnchor="middle"
                        dominantBaseline="middle"
                    >
                        {value}
                    </text>
                </g>
            );
        }
        return (
            <Fragment>
                <div className="ts-mod-header ts-mod-header--border"><span className="ts-mod-header__title">Operator spend</span></div>

                <div className="ts-block__content-section ts-block__content-section--short chart-operator-spend">
                    <ResponsiveContainer
                        height={400}
                        width="100%"
                    >
                        <BarChart width={150} height={240} data={resultShareOfOperatorSpendChart} margin={{top: 20, right: 0, left: 0, bottom: 0}}>
                            <XAxis
                                dataKey="name"
                                height={210}
                                tick={CustomizedAxisTick}
                                interval={0}
                                //axisLine={false}
                                tickLine={false}
                                strokeWidth={1}
                            />
                            <Bar
                                dataKey='point'
                                isAnimationActive={false}
                            >

                                <LabelList
                                    dataKey="value"
                                    position="top"
                                    content={renderCustomizedLabel}
                                />

                                {
                                    resultShareOfOperatorSpendChart.map((entry, index) => (
                                        <Cell
                                            fill={entry.activeSegment ? '#56C734' : '#C4C4C4' }
                                            key={`cell-${index}`}
                                        />
                                    ))
                                }
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </Fragment>
        )
    }
}