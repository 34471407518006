import React, { Component, Fragment } from 'react';
import './style.scss';
import { Link, NavLink } from 'react-router-dom';

import SpriteIcon from "components/main/sprite-icon";

import DSLogo from 'assets/images/Datassential_Text_White.png';
import { Icons } from "../../../core/icons";

export default class Navigation extends Component {
    constructor(state){
        super(state);
        this.state = {
            menuVisible: false,
            pageToRedirect: '',
            //modal access
            popupTargetName: 'Chains',
            popupTarget: 'Chains',
            popupType: 'snap'
        };
        this.onMouseEnterHandlerBody = this.onMouseEnterHandlerBody.bind(this);
        this.onMouseLeaveHandlerBody = this.onMouseLeaveHandlerBody.bind(this);
    }
    componentDidMount() {}
    onMouseEnterHandlerBody() {
        this.setState({
            menuVisible: true
        });
    }
    onMouseLeaveHandlerBody() {
        this.setState({
            menuVisible: false
        });
    }
    render() {
        return (
            <Fragment>
                <div
                    className={"nav-holder jalousie" + (this.state.menuVisible ? ' jalousie--opened' : '')}
                    onMouseEnter={this.onMouseEnterHandlerBody.bind(this)}
                    onMouseLeave={this.onMouseLeaveHandlerBody.bind(this)}
                >
                    <Link
                        to="/"
                        className="main-header__logo-holder nav-trigger"
                    >
                        <div className="main-header__nav-logo">
                            <Icons.Logo
                                className="main-header__nav-logo-img"
                            />
                            <div className="main-header__nav-logo-text">
                                Frosty Acres
                            </div>
                        </div>
                    </Link>
                    <div className={"main-header__trick"}></div>
                    <div className={"main-nav jalousie__list"}>
                        {/*Landscape child*/}
                        <NavLink
                            to="/markets"
                            activeClassName='is-active'
                            className="main-nav__item main-nav__child is-landscape jalousie__item"
                        >
                            <span className="main-nav__menu-item-mark"></span>Markets
                        </NavLink>

                        <NavLink
                            to="/segments"
                            activeClassName='is-active'
                            className="main-nav__item main-nav__child is-landscape jalousie__item">
                            <span className="main-nav__menu-item-mark"></span>Segments
                        </NavLink>

                        <div className="main-nav__footer is-animate-footer jalousie__item">
                            <div className="main-nav__footer-inner">
                                {/*<a*/}
                                {/*    href="https://www.nafem.org/"*/}
                                {/*    className="main-nav__footer-link" target="_blank"*/}
                                {/*>*/}
                                {/*    <span className="main-nav__footer-link-icon-holder">*/}
                                {/*        <SpriteIcon*/}
                                {/*            iconId="arrow-target"*/}
                                {/*            className="main-nav__footer-link-icon"*/}
                                {/*        />*/}
                                {/*    </span>*/}
                                {/*    <span className="main-nav__footer-link-text">Member dashboard</span>*/}
                                {/*</a>*/}

                                <div className="main-nav__footer-logos">
                                    <div className="main-nav__footer-logos-inner">
                                        <div className="main-nav__footer-logos-item">
                                            <a href="https://datassential.com/" target="_blank">
                                                <img src={DSLogo} className="main-nav__ds-logo main-nav__copy-logo"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}