import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './style.scss';

import {
    getConsumerData,
} from 'containers/Metro/metroActions';

import Table from 'components/main/table-general';

import Density from 'components/metro/consumer/graph/density';
import EthnicPopulation from 'components/metro/consumer/graph/ethnic-population';
import TotalPopulation from 'components/metro/consumer/graph/total-population';
import MethodCommute from 'components/metro/consumer/graph/method-commute';
import BornChart from 'components/metro/consumer/graph/born-chart';
import LanguageSpoken from 'components/metro/consumer/graph/language-spoken';
import Generations from 'components/metro/consumer/graph/generations';
import MedianEmployed from 'components/metro/consumer/graph/median-employed';
import WorkingPopulation from 'components/metro/consumer/graph/working-population';


class ConsumerIndex extends Component {
    constructor(state) {
        super(state);
        this.state = {
            activeTabId: 0,
        };
    }
    componentDidMount() {
        const metroId = this.props.metroId;
        const type = this.props.type;
        this.props.getConsumerData(metroId, type);
        window.scrollTo(0, 0);
    }
    changeActiveTab(id) {
        this.setState({activeTabId: id});
        window.scrollTo(0, 0);
    }
    renderTabs() {
        const {metroId, marketConsumer} = this.props;
        const marketConsumerTables = marketConsumer && marketConsumer.tables;
        switch (this.state.activeTabId) {
            case 0: {
                return (
                    <Fragment>
                        <div className="container-row ts-block__content-section bordered">
                            <div className="container-row__col">
                                <Density
                                    metroId={metroId}
                                    marketConsumer={marketConsumer.consumer_snapshot}
                                />
                                <EthnicPopulation
                                    metroId={metroId}
                                    marketConsumer={marketConsumer.consumer_snapshot}
                                />
                                <MethodCommute
                                    marketConsumer={marketConsumer.consumer_snapshot}
                                />
                                <Generations
                                    metroId={metroId}
                                    marketConsumer={marketConsumer.consumer_snapshot}
                                />
                            </div>
                            <div className="container-row__col">
                                <TotalPopulation
                                    marketConsumer={marketConsumer.consumer_snapshot}
                                />
                                <BornChart
                                    marketConsumer={marketConsumer.consumer_snapshot}
                                />
                                <div className="ts-block__content-modul border-bottom">
                                    <LanguageSpoken
                                        marketConsumer={marketConsumer.consumer_snapshot}
                                    />
                                </div>
                                <MedianEmployed
                                    metroId={metroId}
                                    marketConsumer={marketConsumer.consumer_snapshot}
                                />
                            </div>
                        </div>
                        <WorkingPopulation
                            WorkingPopulation={marketConsumer.business}
                        />
                    </Fragment>
                )
            }
            case 1: {
                return (
                    <Fragment>
                        <div className="container-row ts-block__content-section">
                            <div className="container-row__col">
                                <div className="ts-block__content-modul">
                                    <div className="ts-mod-header ts-mod-header--table-view">TOTAL RESTAURANTS PER 1,000 CONSUMERS IN THAT AREA</div>
                                    <Table
                                        tableData={marketConsumerTables && marketConsumerTables.density}
                                        tableClass=""
                                        firstColClass=""
                                        colClass=""
                                        hover={false}
                                    />
                                </div>
                                <div className="ts-block__content-modul">
                                    <div className="ts-mod-header ts-mod-header--table-view">ETHNIC POPULATION</div>
                                    <Table
                                        tableData={marketConsumerTables && marketConsumerTables.ethnic_population}
                                        tableClass=""
                                        firstColClass=""
                                        colClass=""
                                        hover={false}
                                    />
                                </div>
                                <div className="ts-block__content-modul border-bottom">
                                    <div className="ts-mod-header ts-mod-header--table-view">METHOD OF COMMUTE</div>
                                    <Table
                                        tableData={marketConsumerTables && marketConsumerTables.method_of_commute}
                                        tableClass=""
                                        firstColClass=""
                                        colClass=""
                                        hover={false}
                                    />
                                </div>
                                <div className="ts-block__content-modul">
                                    <Table
                                        tableData={marketConsumerTables && marketConsumerTables.income_employed}
                                        tableClass=""
                                        firstColClass=""
                                        colClass=""
                                        hover={false}
                                    />
                                </div>
                            </div>
                            <div className="container-row__col">
                                <div className="ts-block__content-modul">
                                    <div className="ts-mod-header ts-mod-header--table-view">TOTAL POPULATION</div>
                                    <Table
                                        tableData={marketConsumerTables && marketConsumerTables.population}
                                        tableClass=""
                                        firstColClass=""
                                        colClass=""
                                        hover={false}
                                    />
                                </div>
                                <div className="ts-block__content-modul">
                                    <div className="ts-mod-header ts-mod-header--table-view">LANGUAGES SPOKEN NEARBY</div>
                                    <Table
                                        tableData={marketConsumerTables && marketConsumerTables.languages}
                                        tableClass=""
                                        firstColClass=""
                                        colClass=""
                                        hover={false}
                                    />
                                </div>
                                <div className="ts-block__content-modul">
                                    <div className="ts-mod-header ts-mod-header--table-view">Generations</div>
                                    <Table
                                        tableData={marketConsumerTables && marketConsumerTables.generations}
                                        tableClass=""
                                        firstColClass=""
                                        colClass=""
                                        hover={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <WorkingPopulation
                            WorkingPopulation={marketConsumer.business}
                        />
                    </Fragment>
                )
            }
        }
    }
    render() {
        return (
            <Fragment>
                <div className="ts-section-header">
                    <div className="ts-section-header__title ts-section-header__col">
                        <span className="ts-section-header--text-uppercase">CONSUMER SNAPSHOT</span>
                    </div>
                    <div className="ts-filter ts-section-header__col">
                        <div className="">
                            <span className="ts-filter__label">View</span>
                            <ul className="ts-filter__list">
                                {consumerTabs.map((tab, index) => {
                                    return <li className="ts-filter__item" key={index}>
                                        <input
                                            type="radio"
                                            name="switch_consumer"
                                            className="ts-filter__radiobutton"
                                            id={index}
                                            defaultChecked={tab.isChecked ? true : false}
                                            onChange={this.changeActiveTab.bind(this, tab.id)}
                                        />
                                        <label className="ts-filter__switch-label" htmlFor={index}>{tab.name}</label>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                {this.renderTabs()}
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    marketConsumer: state.metroConsumerReducer.consumer_snapshot,
});

const mapDispatchToProps = dispatch => ({
    getConsumerData: (propId, type) => dispatch(getConsumerData(propId, type)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ConsumerIndex);

const consumerTabs = [
    {
        name: 'Graph',
        id: 0,
        isChecked: true
    },
    {
        name: 'Table',
        id: 1,
        isChecked: false
    }
];