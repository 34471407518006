import React, { Component, Fragment } from 'react';
import { connect, match } from 'react-redux';
import './style.scss';

import Icon from 'components/main/svg-component';
import ModalAccess from 'components/main/modal-access';

import {
    getMajorOnSiteData,
    updateMajorOnSite
} from 'containers/Metro/metroActions';

import SwitchFilter from 'components/main/switch-filter';
import Table from 'components/main/table-general';
import Dropdown from 'components/main/dropdown';
import PaginationTable from 'components/main/pagination-table';
import ifmaMarketsTotalUS from 'assets/images/ifma-markets-total_us-blurred.jpg';

class MajorOnSite extends Component {
    constructor(state) {
        super(state);
        this.state = {
            majorInstitutionsSwitchParentId: 17,
            schoolDropdownId: 0,
            k12Label: "All K-12 types",
            dropdownsVisible: true,
            activePagechainArea: 1,
            tableSort: 'count',
            tableSortType: 'desc',
            searchFieldValue: '',
            searching: false,
            //modal
            modalVisible: this.props.access.accessFirefly ? false : true,
            //modalVisible: false,
            popupTargetName: 'On-Site',
            popupTarget: 'On-Site',
            popupType: 'firefly',
            ModalLoginVisible: false
        };
        this.tableSearch = this.tableSearch.bind(this);
        this.successModal = this.successModal.bind(this);
        this.hideModalLogin = this.hideModalLogin.bind(this);
        this.updateContentAfterLogin = this.updateContentAfterLogin.bind(this);
    }

    tableSearch(event) {
        this.setState({
            searchFieldValue: event.target.value,
            searching: true
        }, function () {
            this.updateContent();
        });
    }

    componentDidMount() {
        const metroId = this.props.metroId;
        const type = this.props.type;
        this.props.getMajorOnSiteData(metroId, type);
        window.scrollTo(0, 0);
    }

    changeParentId(value, group) {
        if (value == 17) {
            this.setState({
                dropdownsVisible: true,
                majorInstitutionsSwitchParentId: value,
                activePagechainArea: 1
            }, function () {
                this.updateContent();
            });
        }  else {
            this.setState({
                dropdownsVisible: false,
                majorInstitutionsSwitchParentId: value,
                activePagechainArea: 1,
                schoolDropdownId: 0,
            }, function () {
                this.updateContent();
            });
        }
    }

    changeSchoolDropdown(value, label) {
        this.setState({
            schoolDropdownId: value,
            k12Label: label,
            activePagechainArea: 1
        }, function () {
            this.updateContent();
        });
    }

    changePage(page) {
        this.setState({
            activePagechainArea: page,
        }, function () {
            this.updateContent();
        });
    }

    sortingTable(colKey) {
        const newTableSortType = colKey !== this.state.tableSort ? 'asc' : this.state.tableSortType === 'desc' ? 'asc' : 'desc';
        this.setState({
            tableSort: colKey,
            tableSortType: newTableSortType
        }, function () {
            this.updateContent();
        });
    }

    updateContent() {
        const metroId = this.props.metroId;
        const type = this.props.type;
        this.props.updateMajorOnSite(
            metroId,
            type,
            this.state.activePagechainArea,
            this.state.majorInstitutionsSwitchParentId,
            this.state.schoolDropdownId,
            this.state.tableSort,
            this.state.tableSortType,
            this.state.searchFieldValue
        );
    }
    successModal() {
        this.props.actionModal(0)
    }
    hideModalLogin(id) {
        this.props.actionModal(id)
    }
    updateContentAfterLogin() {
        this.updateContent();
    }

    render() {
        const majorOnSiteCopy = this.props.majorOnSite && this.props.majorOnSite;
        const tableData = this.props.majorOnSite && majorOnSiteCopy.table;
        const tablePagination = tableData && tableData.pagination.page_count;
        const activePagechainArea = this.state.activePagechainArea;
        const {metroCurrentId, metroId} = this.props;
        return (
            <Fragment>
                {this.props.access.accessFirefly && <Fragment>
                    <div className="ts-section-header">
                        <div className="ts-section-header__title ts-section-header__col">
                            <span className="ts-section-header--text-uppercase">Major on-site institutions</span>
                        </div>
                        <div className="ts-filter ts-section-header__col">
                            <div className="">
                                <span className="ts-filter__label">Show by</span>
                                <SwitchFilter
                                    switchData={majorInstitutionsSwitch}
                                    group="majorInstitutionsSwitch"
                                    prefixId="majorInstitutionsSwitch"
                                    checked={this.state.majorInstitutionsSwitchParentId}
                                    action={this.changeParentId.bind(this)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="header-filters">
                        <div className="header-filters__col header-filters__col-full">
                            <div className="search-simple">
                                <div className="main-search__icon main-search__icon--right">
                                    <Icon iconClassName='search' />
                                    {/*{this.state.searching ? (<Icon iconClassName='oval' />) : (<Icon iconClassName='search' />)}*/}
                                </div>
                                <input
                                    className="search-simple__input"
                                    type="text"
                                    placeholder="Search by name"
                                    value={this.state.searchFieldValue}
                                    onChange={this.tableSearch}
                                />
                            </div>
                        </div>
                        {this.state.dropdownsVisible &&
                        <div className="header-filters__col header-filters__col-small">
                            <div className="ts-filter ts-filter--in-container ts-filter__container header-filters__inner">
                                <span className="ts-filter__label ts-filter__label--left header-filters__inner-col">K-12 Types</span>
                                <div className="select-mod header-filters__inner-col">
                                    <Dropdown
                                        filtersList={this.props.schoolDropdown}
                                        orderByLabel={this.state.k12Label}
                                        action={this.changeSchoolDropdown.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                        }

                    </div>

                    <div className="ts-block__content-section relative">
                        <Table
                            tableData={metroCurrentId === metroId ? tableData : null}
                            tableclassName=""
                            firstColclassName=""
                            colclassName=""
                            hover={false}
                            sortingTableAction={this.sortingTable.bind(this)}
                            tableSort={this.state.tableSort}
                            tableSortType={this.state.tableSortType}
                        />

                        {tablePagination > 1 && <PaginationTable
                            tablePagination={tablePagination}
                            action={this.changePage.bind(this)}
                            activePagechainArea={activePagechainArea}
                        />}
                    </div>
                </Fragment>}
                {(this.props.access.accessNafem && !this.props.access.accessFirefly) && <div>
                    <img src={ifmaMarketsTotalUS} className=""/>
                    <ModalAccess
                        popupTargetName={this.state.popupTargetName}
                        popupTarget={this.state.popupTarget}
                        popupType={this.state.popupType}
                        modalVisible={this.state.modalVisible}
                        successModal={this.successModal.bind(this)}
                    />
                </div>}
            </Fragment>
        )
    }
}

const majorInstitutionsSwitch = [
    {
        title: 'K-12',
        value: 17
    },
    {
        title: 'C&U',
        value: 18
    },
    {
        title: 'Health',
        value: 9
    }
];

const mapStateToProps = state => ({
    access: state.landingpageReducer.access,
    metroCurrentId: state.metropageReducer.metroCurrentId,
    majorOnSite: state.metroOperatorReducer.majorOnSite,
    schoolDropdown: state.metroOperatorReducer.schoolDropdown,
    chainsRestaurantCuisines: state.metroOperatorReducer.chainsRestaurantCuisines,
});

const mapDispatchToProps = dispatch => ({
    getMajorOnSiteData: (
        propId,
        type,
        majorInstitutionsSwitchParentId
    ) => dispatch(getMajorOnSiteData(
        propId,
        type,
        majorInstitutionsSwitchParentId
    )),
    updateMajorOnSite: (
        propId,
        type,
        page,
        majorInstitutionsSwitchParentId,
        schoolDropdownId,
        tableSort,
        tableSortType,
        name
    ) => dispatch(updateMajorOnSite(
        propId,
        type,
        page,
        majorInstitutionsSwitchParentId,
        schoolDropdownId,
        tableSort,
        tableSortType,
        name
    )),
});
export default connect(mapStateToProps, mapDispatchToProps)(MajorOnSite);