import React, { Component } from 'react';
import './style.scss';

export default class MethodCommute extends Component {

    render() {
        const methodOfCommuteCopy = this.props.marketConsumer && this.props.marketConsumer.method_of_commute;

        const methodCommuteArr = methodOfCommuteCopy && methodOfCommuteCopy.map((result, index) => {
            return (<div className="method-commute__col" key={index}>
                <div className="method-commute__data">{result.percent}<span className="method-commute__percent">%</span></div>
                <div className="total-population__title method-commute__title">
                    <span className="method-commute__text-title method-commute__text-title--drive_alone">{result.label}</span>
                </div>
            </div>);
        });
        return (
            <div className="ts-block__content-modul border-bottom">
                <div className="ts-mod-header">METHOD OF COMMUTE</div>
                <div className="total-population method-commute clearfix">
                    {methodCommuteArr}
                </div>
            </div>
        )
    }
}