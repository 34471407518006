import React, { Component } from 'react';
import './style.scss';

import SpriteIcon from "components/main/sprite-icon";

import DSLogo from 'assets/images/Datassential_Text_White.png';
import FooterBg from 'assets/images/footer-bg.png';
import { IconDsLogoWhite } from "../../../core/icons";

export default class Footer extends Component {

    render() {
        return (
            <div className="main-footer">
                <div
                    className="main-footer__content"
                >
                    <div className="main-footer__copy main-footer__el-full">
                        &copy; {(new Date().getFullYear())} Frosty Acres Brands, All rights reserved
                    </div>
                    <div className="main-footer__logos-text main-footer__el-small">Built in collaboration with:</div>
                    <div className="main-footer__logo-holder main-footer__el-small">
                        <a href="https://datassential.com/" target="_blank">
                            <IconDsLogoWhite className="main-footer__ds-logo"/>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}