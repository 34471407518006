import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import Icon from 'components/main/svg-component';
import ModalAccess from 'components/main/modal-access';
import { __fetch } from 'App';
import { FF_API_URL } from "core/constants";
import { getAccess } from "containers/Landing/landingActions";

let controller;
let signal;

let timer = null;

class MainSearch extends Component {
    constructor(state){
        super(state);
        this.state = {
            chains: [],
            chainsLength: 0,
            resultVisible: false,
            searchFieldValue: '',
            searching: false,
            modalVisible: false,
            popupTargetName: '',
            popupTarget: '',
            popupType: '',
            loginActive: false,
            ndaActive: false,
            targetURL: ''
        };
        this.bySearch = this.bySearch.bind(this);
        this.byFocus = this.byFocus.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.clickLink = this.clickLink.bind(this);
        this.goLink = this.goLink.bind(this);
        this.byEnter = this.byEnter.bind(this);
        this.successModal = this.successModal.bind(this);
        this.showModalLogin = this.showModalLogin.bind(this);
        this.hideModalLogin = this.hideModalLogin.bind(this);
        this.showNdaModal = this.showNdaModal.bind(this);
    }
    bySearch(event) {
        const _this = this;
        let value;

        if (controller !== undefined) {
            // Cancel the previous request
            controller.abort();
        }

        if ("AbortController" in window) {
            controller = new AbortController;
            signal = controller.signal;
        }

        this.setState({
            searchFieldValue: event.target.value,
            searching: true
        });
        if (!this.state.resultVisible) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        if(event.target.value !== '') {
            value = event.target.value;
        } else {
            value = null;
        }


        if(event.target.value.length > 2) {
            const searchUrl = `${FF_API_URL}/search/index?term=` + encodeURIComponent(value);
            clearTimeout(timer);
            timer = setTimeout(function() {
               __fetch(searchUrl, {signal})
                    .then(res => res.json())
                    .then(data => {
                        _this.setState({
                            chains: data,
                            resultVisible: true,
                            chainsLength: data.groups.length,
                            searching: false
                        });
                    })
                    .catch(err => {
                        if (err.name === 'AbortError') {
                            //console.log('Fetch aborted');
                        }
                    })
                ;
            }, 300);

        } else {
            this.setState({
                chains: [],
                resultVisible: false,
                chainsLength: 0,
                searching: false
            });
        }
    }
    byEnter(event) {
        const value = event.target.value;
        const searchLink = `${process.env.REACT_APP_FIREFLY_URL}/search/quick-search/search-by-title?title=${value}`;
        if(event.key === 'Enter') {
            if(this.props.access.accessNafem) {
                window.open(searchLink, '_blank');
            } else {
                this.setState({
                    ModalLoginVisible: true,
                });
            }
        }
    }
    byFocus() {
        if(this.state.chainsLength > 0) {
            this.setState({
                resultVisible: true
            });
        }
        document.addEventListener('click', this.handleOutsideClick, false);
    }
    handleOutsideClick(e) {
        if (this.node.contains(e.target)) {
            return;
        }
        this.handleClick();
    }
    clickLink(type, target, name) {
        this.handleClick();
        this.setState({
            modalVisible: true,
            popupTargetName: name,
            popupTarget: target,
            popupType: type,
            chains: [],
            resultVisible: false,
            chainsLength: 0,
            searching: false,
            searchFieldValue: '',
        });
    }
    goLink() {
        this.handleClick();
        this.setState({
            chains: [],
            resultVisible: false,
            chainsLength: 0,
            searching: false,
            searchFieldValue: '',
        });
    }
    handleClick() {
        if (!this.state.resultVisible) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
            this.setState({
                chains: [],
                resultVisible: false,
                chainsLength: 0,
                searching: false,
                searchFieldValue: '',
            });
        }
        if(this.state.chainsLength > 0 && this.state.resultVisible === true) {
            this.setState({
                resultVisible: !this.state.resultVisible
            });
        }
    }
    successModal() {
        this.setState({
            modalVisible: false,
            popupTargetName: '',
            popupTarget: '',
            popupType: '',
        });
    }
    showModalLogin(target_url) {
        this.setState({
            loginActive: true,
            chains: [],
            resultVisible: false,
            chainsLength: 0,
            searching: false,
            searchFieldValue: '',
            targetURL: target_url
        });
    }
    hideModalLogin(popupType, popupTarget) {
        this.setState({
            loginActive: false,
        });
        if(popupTarget !== undefined) {
            this.clickLink(popupType, popupTarget, popupType);
        }
    }
    showNdaModal(param) {
        this.setState({
            ndaActive: param,
        });
    }
    render() {
        return (
            <Fragment>
                <div className="main-search" ref={node => {this.node = node;}}>
                    <div className="main-search__icon">
                        {this.state.searching ? (<Icon iconClassName='oval' />) : (<Icon iconClassName='search2' />)}
                    </div>
                    <form autoComplete='off'>
                        <input type="text"
                               value={this.state.searchFieldValue}
                               className="main-search__field"
                               placeholder={this.props.access.accessFirefly ? "search Firefly..." : "find a chain..."}
                               onChange={this.bySearch}
                               onFocus={this.byFocus}
                               autoComplete="new-search"
                        />
                    </form>

                    <ItemsList
                        chainList = {this.state.chains}
                        resultVisible = {this.state.resultVisible}
                        clickLink = {this.clickLink}
                        goLink = {this.goLink}
                        access={this.props.access}
                    />
                </div>
                <ModalAccess
                    popupTargetName={this.state.popupTargetName}
                    popupTarget={this.state.popupTarget}
                    popupType={this.state.popupType}
                    modalVisible={this.state.modalVisible}
                    successModal={this.successModal.bind(this)}
                />
            </Fragment>
        )
    }
}

class ItemsList extends React.Component {
    render() {
        const { chainList, resultVisible, clickLink, goLink, access } = this.props;
        const chainListGroups = chainList.groups;
        return (
            <Fragment>
                <div className={resultVisible ? "main-search__list-holder is-open" : "main-search__list-holder"}>
                    <Scrollbars autoHeight autoHeightMax={300}>
                        <ul className="main-search__list">
                            {
                                chainListGroups && Object.keys(chainListGroups).length > 0 ? (
                                    chainListGroups.map((group, index) => {
                                        const name = group.name;
                                        return <li key={index} className={'main-search__group' + ' is-' + name.toLowerCase()}>
                                            <div className='main-search__group-title'>
                                                <div className='main-search__group-name'>{group.name}</div>
                                                <div className='main-search__group-hint'>{group.hint}</div>
                                                </div>
                                            <ul>
                                                {
                                                    group.items.map((item, i) => {
                                                        const itemLabel = item.label;

                                                        if(item.url !== undefined) {
                                                            return <li key={i} className='main-search__item'>
                                                                <a
                                                                    href={item.url}
                                                                    target="_blank"
                                                                    className='main-search__name'
                                                                    onClick={goLink.bind(this)}
                                                                >
                                                                    {itemLabel}
                                                                    {item.city_state !== undefined
                                                                        ? <Fragment><br/><span className="main-search__city-state">{item.city_state}</span></Fragment>
                                                                        : null
                                                                    }
                                                                </a>
                                                            </li>
                                                        } else {
                                                            return <li key={i} className='main-search__item'>
                                                                <span
                                                                    onClick={clickLink.bind(this, item.popup.type, item.popup.target, itemLabel)}
                                                                    className='main-search__name'
                                                                >
                                                                    {itemLabel}
                                                                    {item.city_state !== undefined
                                                                        ? <Fragment><br/><span className="main-search__city-state">{item.city_state}</span></Fragment>
                                                                        : null
                                                                    }
                                                                </span>
                                                            </li>
                                                        }

                                                    })
                                                }
                                            </ul>
                                        </li>
                                    })

                                ) : (
                                    resultVisible && (<li className={resultVisible ? "main-search__item" : "main-search__item is-hidden"}>No results</li>)
                                )
                            }
                        </ul>
                    </Scrollbars>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    searchData: state.landingpageReducer.searchData,
});

const mapDispatchToProps = dispatch => ({
    getAccess: () => dispatch(getAccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainSearch);