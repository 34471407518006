import React, { Component, Fragment, PropTypes } from 'react';
import { connect, match } from 'react-redux';

import { Link } from 'react-router-dom';

import { getAccess } from 'containers/Landing/landingActions';

import { getData, getSegments, getNotableOperators, getNotableOperatorsTabs } from './segmentsActions';

import Header from 'components/main/header';
import { EXPORT_PPT_SEGMENTS } from "../../core/constants";


class Segments extends Component {

    constructor(state) {
        super(state);
        this.state = {
            segmentId: null,
            segmentName: '',
            notableOperatorsSort: '',
            notableOperatorsSortType: '',
            headerSticky: false
        };
        this.showSegmentList = this.showSegmentList.bind(this);
        this.segmentsClose = this.segmentsClose.bind(this);
        this.getNotableOperators = this.getNotableOperators.bind(this);
        this.windowScroll = this.windowScroll.bind(this);
    }

    componentDidMount() {
        document.title = 'Segments | Frosty Acres Brands';
        window.addEventListener('scroll', this.windowScroll);
        this.props.getAccess();
        this.props.getSegments();
        // this.getNotableOperators();
        window.scrollTo(0, 0);
        this.setState({
            segmentsListVisible: this.state.segmentId === null ? true : false,
        })
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.windowScroll);
    }

    windowScroll() {
        const scrollPosition = window.scrollY;
        if(scrollPosition >= 4) {
            this.setState({headerSticky: true});
        } else {
            this.setState({headerSticky: false});
        }
        this.setState({
            segmentsListVisible: false,
        })
    }

    getNotableOperators (sort, sortType) {
        if (this.state.segmentId) {
            if(sort !== undefined || sortType !== undefined) {
                this.setState({
                    notableOperatorsSort: sort,
                    notableOperatorsSortType: sortType,
                }, function () {
                    this.props.getNotableOperators(this.state.segmentId, this.state.notableOperatorsSort, this.state.notableOperatorsSortType);
                    this.props.getNotableOperatorsTabs(this.state.segmentId);
                })
            } else {
                this.props.getNotableOperators(this.state.segmentId, this.state.notableOperatorsSort, this.state.notableOperatorsSortType);
                this.props.getNotableOperatorsTabs(this.state.segmentId);
            }
        }
    }
    showSegmentList() {
        this.setState({
            segmentsListVisible: !this.state.segmentsListVisible
        });
    }
    segmentsClose() {
        this.setState({
            segmentsListVisible: false
        });
    }

    clickToExportPPT() {
        window.location.href = EXPORT_PPT_SEGMENTS + this.state.segmentId;
    }

    render() {
        const path = this.props.match.path;
        const segmentsList = this.props.segmentsList && this.props.segmentsList;
        const segmentsListLength = Object.keys(segmentsList).length;
        return (
        <Fragment>
            <div className="wrap">
                <Header
                    path={path}
                    accessSnap={this.props.access.accessSnap}
                    accessNafem={this.props.access.accessNafem}
                    pageTitle='Segments'
                />
                <div className={"container "}>
                    <div className={'segments content-shadow'}>
                        <div className="i-row i-row--wrap">
                            {Object.values(segmentsList).map((cell, index) => {
                                const childrenList = cell.children;
                                const isWide = cell.name.trim().toLowerCase() === 'recreation'

                                return <div key={index} className={"i-col" + (isWide ? ' i-col-1-2' : ' i-col-1-4')}>
                                    <div className="segments__name">{cell.name}</div>
                                    <ul className={"segments__list" + (isWide ? ' i-row i-row--wrap' : '')}>
                                        {childrenList.map((child, i) => {
                                            return <li key={i} className={isWide ? ' i-col i-col-1-2' : ''}>
                                                <Link
                                                    to={`/segments/${child.id}`}
                                                    className={"geo-filter__item" + (child.id === this.state.segmentId ? ' is-checked' : '')}
                                                ><span className='geo-filter__item-title'>{child.name}</span></Link>
                                            </li>
                                        })}
                                    </ul>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    access: state.landingpageReducer.access,
    segmentsList: state.segmentspageReducer.segmentsList,
    segmentsData: state.segmentspageReducer.segmentsData,
    notableOperators: state.segmentspageReducer.notableOperators,
    notableOperatorsTabs: state.segmentspageReducer.notableOperatorsTabs,
});

const mapDispatchToProps = dispatch => ({
    getData: (id) => dispatch(getData(id)),
    getSegments: () => dispatch(getSegments()),
    getAccess: () => dispatch(getAccess()),
    getNotableOperators: (segmentId, sort, sortType) => dispatch(getNotableOperators(segmentId, sort, sortType)),
    getNotableOperatorsTabs: (segmentId) => dispatch(getNotableOperatorsTabs(segmentId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Segments);