export const API_URL = `${process.env.REACT_APP_API}/api`;
export const API_LANDING = `${API_URL}/landing`
export const API_SEGMENTS = `${API_URL}/segments`
export const API_FF = `${API_URL}/firefly/request/send`
export const API_FF_BORDERS = `${API_URL}/firefly/request/send-map`

export const EXPORT_LANDING = `${API_LANDING}/default/export-landing`;
export const EXPORT_PPT = `${API_LANDING}/default/ppt-export`;
export const EXPORT_PPT_SEGMENTS = `${API_SEGMENTS}/default/ppt-export`;
export const API_ACCESS = `${API_LANDING}/access`;
export const API_EMAIL_SEND_URL = `${API_LANDING}/default/email-send`;
export const API_LOGIN = `${API_LANDING}/authorization/form-login`;
export const API_IS_FIRST = `${API_LANDING}/default/is-first-time`;

export const FF_API_URL = `${API_FF}`;
export const MAP_API_INITIAL_BORDERS = `${API_FF}/analytics-map/get-initial-borders`;
export const MAP_API_LAYERS = `${API_FF_BORDERS}/analytics-map/get-markers-for-borders`;
export const MAP_API_BORDERS = `${API_FF}/analytics-map/get-borders`;
export const MAP_API_COORDINATES = `${API_LANDING}/analytics-map/get-location-for-coordinates`;

export const LOGIN_URL = `${API_LANDING}/authorization/login`;
export const LOGOUT_URL = `${API_LANDING}/user`;
