import React, { Component, Fragment } from 'react';
import './style.scss';

export default class IndexingCuisines extends Component {
    constructor(state) {
        super(state);
        this.state = {
            hover: false,
            hoverIndex: ''
        };
        this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
        this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    }
    onMouseEnterHandler(index) {
        this.setState({
            hover: true,
            hoverIndex: index
        });
    }
    onMouseLeaveHandler(index) {
        this.setState({
            hover: false,
            hoverIndex: index
        });
    }
    render() {
        const marketAnalysisCopy = this.props.marketAnalysis && this.props.marketAnalysis;
        const highIndexingCuisines = marketAnalysisCopy && marketAnalysisCopy.high_indexing_cuisines;
        return (
            <Fragment>
                <div className="ts-mod-header ts-mod-header--border"><span className="ts-mod-header__title">HIGH INDEXING CUISINES</span></div>
                <div className="indexing ts-block__content-section">
                    <div className="indexing__row">
                        {highIndexingCuisines && highIndexingCuisines.map((item, index) => {
                            return <div className="indexing__item"
                                        key={index}
                                        onMouseEnter={this.onMouseEnterHandler.bind(this, index)}
                                        onMouseLeave={this.onMouseLeaveHandler.bind(this, index)}
                            >
                                <div className="indexing__item-inner">
                                    <span className="qa-cuisine-name">{item.label}</span><br/>
                                    <span className="qa-cuisine-data">{item.index}</span>

                                    <div className={("tip-body tip-body--dark is-indexing is-center") + (this.state.hover && this.state.hoverIndex === index ? ' is-active' : '')}>
                                        <div className="tip-body__block-data">
                                            <span>restaurants in area = {item.count}</span><br/>
                                            <span>share of units = {item.share}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        })}

                    </div>
                </div>
            </Fragment>
        )
    }
}