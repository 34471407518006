import {
    METRO_NAME_GET_DATA,
    METRO_GET_DATA,
    METRO_UPDATE_DATA,
    METRO_GET_TAB,
    METRO_UPDATE_TAB,
    METRO_CUISINES_GET_DATA,
    METRO_CONSUMER_GET_DATA,
    METRO_CUISINES_UPDATE_DATA,
    METRO_CHAINS_AREA_GET_DATA,
    METRO_CHAINS_AREA_UPDATE_DATA,
    METRO_CHAINS_AREA_SEGMENTS_GET_DATA,
    METRO_CHAINS_AREA_RESTAURANT_CUISINES_GET_DATA,

    METRO_MAJOR_ON_SITE_GET_DATA,
    METRO_MAJOR_ON_SITE_UPDATE_DATA,
    METRO_SCOHOL_DROPDOWN_GET_DATA
} from './metroActionTypes';
import Http from 'helpers/http';
import { API_URL, FF_API_URL } from "../../core/constants";

export function getDataMetroGeneral(id, type) {
    return (dispatch) => {
        Http.get(`${API_URL}/markets/default/metro-info/${id}`).then((data) => {
            dispatch(storeDataMetroName(data));
        });
    };
}

export function getDataMetro(id, type) {
    return (dispatch) => {
        Http.get(`${FF_API_URL}/analytics/market-overview?${type}=${id}`).then((data) => {
            dispatch(storeData(data, id));
        });
    };
}

export function getOverviewData(id, type, typeSegment, cuisineType, sort, sortType) {
    return (dispatch) => {
        Http.get(`${FF_API_URL}/analytics/market-cuisines`, {
            [type]: id,
            type: typeSegment,
            cuisineType: cuisineType,
            sort: sort,
            sort_type: sortType
        }).then((data) => {
            dispatch(marketCuisinesData(data));
        });
    };
}

export function updateMarketCuisinesData(id, type, typeSegment, cuisineType, sort, sortType) {
    return (dispatch) => {
        Http.get(`${FF_API_URL}/analytics/market-cuisines`, {
            [type]: id,
            type: typeSegment,
            cuisineType: cuisineType,
            sort: sort,
            sort_type: sortType
        }).then((data) => {
            dispatch(updateMarketCuisines(data));
        });
    };
}

export function getConsumerData(id, type) {
    return (dispatch) => {
        Http.get(`${FF_API_URL}/analytics/consumers?${type}=${id}`).then((data) => {
            dispatch(consumerData(data));
        });
    };
}

export function getOperatorsData(id, type) {
    return (dispatch) => {
        Http.get(`${FF_API_URL}/analytics/operators`, {
            parent_segment_id: 1,
            [type]: id,
            page: 1,
            perPage: 50,
            segment: 0,
            menu_type: 0,
            sort: 'count',
            sort_type: 'desc'
        }).then((data) => {
            dispatch(chainsAreaData(data));
        });

        Http.get(`${FF_API_URL}/analytics/restaurant-segments`, {
            parent_segment_id: 1,
            [type]: id,
        }).then((data) => {
            dispatch(chainsAreaSegmentsData(data));
        });

        Http.get(`${FF_API_URL}/analytics/restaurant-cuisines`, {
            parent_segment_id: 1,
            [type]: id,
        }).then((data) => {
            dispatch(chainsAreaRestaurantCuisinesData(data));
        });
    };
}

export function updateChainAreaData(
    id,
    type,
    page,
    chainAreaSwitchParentId,
    chainAreaSegment,
    chainAreaMenuType,
    chainAreaSort,
    chainAreaSortType,
    name
) {
    return (dispatch) => {
        Http.get(`${FF_API_URL}/analytics/operators`, {
            parent_segment_id: chainAreaSwitchParentId,
            [type]: id,
            page: page,
            perPage: 50,
            segment: chainAreaSegment,
            menu_type: chainAreaMenuType,
            sort: chainAreaSort,
            sort_type: chainAreaSortType,
            name: name
        }).then((data) => {
            dispatch(updateAreaData(data));
        });
    };
}

export function getMajorOnSiteData(id, type) {
    return (dispatch) => {
        Http.get(`${FF_API_URL}/analytics/operators`, {
            parent_segment_id: 17,
            [type]: id,
            page: 1,
            perPage: 50,
            segment: 5,
            sort: 'count',
            sort_type: 'desc',
        }).then((data) => {
            dispatch(majorOnSiteData(data));
        });

        Http.get(`${FF_API_URL}/analytics/k12-types`, {
            parent_segment_id: 17,
            [type]: id,
        }).then((data) => {
            dispatch(schoolDropdown(data));
        });
    };
}

export function updateMajorOnSite(
    id,
    type,
    page,
    chainAreaSwitchParentId,
    schoolDropdownId,
    chainAreaSort,
    chainAreaSortType,
    name
) {
    return (dispatch) => {
        Http.get(`${FF_API_URL}/analytics/operators`, {
            parent_segment_id: chainAreaSwitchParentId,
            [type]: id,
            page: page,
            perPage: 50,
            sector: schoolDropdownId,
            sort: chainAreaSort,
            sort_type: chainAreaSortType,
            name: name
        }).then((data) => {
            dispatch(updateMajorOnSiteData(data));
        });
    };
}

function storeDataMetroName(data) {
    return {
        type: METRO_NAME_GET_DATA,
        data
    }
}

function storeData(data, id) {
    return {
        type: METRO_GET_DATA,
        data, id
    }
}

export function updateData(data) {
    return {
        type: METRO_UPDATE_DATA,
        data
    }
}

function marketCuisinesData(data) {
    return {
        type: METRO_CUISINES_GET_DATA,
        data
    }
}

function schoolDropdown(data) {
    return {
        type: METRO_SCOHOL_DROPDOWN_GET_DATA,
        data
    }
}

export function updateMarketCuisines(data) {
    return {
        type: METRO_CUISINES_UPDATE_DATA,
        data
    }
}

function consumerData(data) {
    return {
        type: METRO_CONSUMER_GET_DATA,
        data
    }
}

function chainsAreaData(data) {
    return {
        type: METRO_CHAINS_AREA_GET_DATA,
        data
    }
}

function updateAreaData(data) {
    return {
        type: METRO_CHAINS_AREA_UPDATE_DATA,
        data
    }
}

function majorOnSiteData(data) {
    return {
        type: METRO_MAJOR_ON_SITE_GET_DATA,
        data
    }
}

function updateMajorOnSiteData(data) {
    return {
        type: METRO_MAJOR_ON_SITE_UPDATE_DATA,
        data
    }
}

function chainsAreaSegmentsData(data) {
    return {
        type: METRO_CHAINS_AREA_SEGMENTS_GET_DATA,
        data
    }
}

function chainsAreaRestaurantCuisinesData(data) {
    return {
        type: METRO_CHAINS_AREA_RESTAURANT_CUISINES_GET_DATA,
        data
    }
}

export function getTab(tabId) {
    return {
        type: METRO_GET_TAB,
        tabId
    }
}

export function updateTab(tabId) {
    return (dispatch) => {
        dispatch(getTab(tabId));
        return {
            type: METRO_UPDATE_TAB,
        }
    };
}