import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { getAccess } from 'containers/Landing/landingActions';

import { getData, getSegments, getNotableOperators, getNotableOperatorsTabs } from 'containers/Segments/segmentsActions';

import Header from 'components/main/header';
import SummarySegments from 'components/segments/summary-segments';
import ShareOperators from 'components/segments/share-operators';

import Icon from 'components/main/svg-component';
import { EXPORT_PPT_SEGMENTS } from "../../core/constants";


class ItemSegment extends Component {

    constructor(state) {
        super(state);
        this.state = {
            segmentId: null,
            segmentName: '',
            segmentsListVisible: false,
            notableOperatorsSort: '',
            notableOperatorsSortType: '',
            headerSticky: false
        };
        this.segmentClick = this.segmentClick.bind(this);
        this.showSegmentList = this.showSegmentList.bind(this);
        this.segmentsClose = this.segmentsClose.bind(this);
        this.getNotableOperators = this.getNotableOperators.bind(this);
        this.windowScroll = this.windowScroll.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        this.setState({
            segmentId: this.props.match.params.id
        })
        document.title = 'Segment | Frosty Acres Brands';
        window.addEventListener('scroll', this.windowScroll);
        this.props.getAccess();
        this.props.getSegments();
        this.props.getData(id);
        // this.props.getNotableOperatorsTabs(id);
        // this.props.getNotableOperators(id, this.state.notableOperatorsSort, this.state.notableOperatorsSortType);
        window.scrollTo(0, 0);
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.windowScroll);
    }

    windowScroll() {
        const scrollPosition = window.scrollY;
        if(scrollPosition >= 4) {
            this.setState({headerSticky: true});
        } else {
            this.setState({headerSticky: false});
        }
        this.setState({
            segmentsListVisible: false,
        })
    }

    getNotableOperators (sort, sortType) {
        if (this.state.segmentId) {
            if(sort !== undefined || sortType !== undefined) {
                this.setState({
                    notableOperatorsSort: sort,
                    notableOperatorsSortType: sortType,
                }, function () {
                    this.props.getNotableOperators(this.state.segmentId, this.state.notableOperatorsSort, this.state.notableOperatorsSortType);
                    this.props.getNotableOperatorsTabs(this.state.segmentId);
                })
            } else {
                this.props.getNotableOperators(this.state.segmentId, this.state.notableOperatorsSort, this.state.notableOperatorsSortType);
                this.props.getNotableOperatorsTabs(this.state.segmentId);
            }
        }
    }

    segmentClick(id, name) {
        this.setState({
            segmentId: id,
            segmentName: name,
        }, function () {
            this.setState({
                segmentsListVisible: false
            });
            window.scrollTo(0, 0);
            this.props.getData(id);
            this.props.getNotableOperators(this.state.segmentId, this.state.notableOperatorsSort, this.state.notableOperatorsSortType);
        });
    }
    showSegmentList() {
        this.setState({
            segmentsListVisible: !this.state.segmentsListVisible
        });
    }
    segmentsClose() {
        this.setState({
            segmentsListVisible: false
        });
    }

    clickToExportPPT() {
        window.location.href = EXPORT_PPT_SEGMENTS + this.state.segmentId;
    }

    render() {
        const path = this.props.match.path;
        const segmentsList = this.props.segmentsList && this.props.segmentsList;
        const segmentsListLength = Object.keys(segmentsList).length;
        const segmentsData = this.props.segmentsData && this.props.segmentsData;
        const segmentName = this.props.segmentsData && this.props.segmentsData.name;
        const segmentId = this.props.match.params.id;

        const titleSegmentPage = segmentName !== undefined ? segmentName + ' | ' : '';
        document.title = titleSegmentPage + 'Frosty Acres Brands';
        return (
        <Fragment>
            <div className="wrap">
                <Header
                    path={path}
                    accessNafem={this.props.access.accessNafem}
                    accessSnap={this.props.access.accessSnap}
                    pageTitle='Segment'
                />

                <div className={"main-title" + (this.state.headerSticky ? ' is-sticky': '')}>
                    <div className="main-title__inner main-title__inner--c-start">
                        <div className="main-title__text">{segmentName}</div>
                        <div className="main-title__action main-title__action--lowercase" onClick={this.showSegmentList.bind(this)}>
                            Change segment
                        </div>
                    </div>
                    <div className="modal-helper container">
                        {this.state.segmentsListVisible &&
                        <div className={'segments' + (this.state.segmentId !== null ? ' is-modal' : '')}>
                            {this.state.segmentId !== null &&
                            <span className="segments__close-button" onClick={this.segmentsClose.bind(this)}><Icon iconClassName='remove' /></span>
                            }
                            <div className="i-row i-row--wrap">
                                {Object.values(segmentsList).map((cell, index) => {
                                    const childrenList = cell.children;

                                    return <div key={index} className={"i-col" + (segmentsListLength - 1 === index ? ' i-col-1-2' : ' i-col-1-4')}>
                                        <div className="segments__name">{cell.name}</div>
                                        <ul className={"segments__list" + (segmentsListLength - 1 === index ? ' i-row i-row--wrap' : '')}>
                                            {childrenList.map((child, i) => {
                                                return <li key={i} className={segmentsListLength - 1 === index ? ' i-col i-col-1-2' : ''}>
                                                    <Link
                                                        to={`/segments/${child.id}`}
                                                        className={"geo-filter__item" + (child.id === this.state.segmentId ? ' is-checked' : '')}
                                                        onClick={this.segmentClick.bind(this, child.id, child.name)}
                                                    >
                                                        <span className='geo-filter__item-title'>{child.name}</span>
                                                    </Link>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                })}
                            </div>
                        </div>
                        }
                    </div>
                </div>

                <div className={"container container--item-segment main-container" + (this.state.headerSticky ? ' is-head-sticky': '')}>
                    {/*export menu*/}
                    {/*{this.state.segmentId !== null &&*/}
                    {/*<div className="gooey-menu">*/}
                    {/*    <div className="gooey-menu__label is-orange"*/}
                    {/*         onClick={this.clickToExportPPT.bind(this)}*/}
                    {/*    >*/}
                    {/*        <span className="gooey-menu__item-icon is-ppt"><Icon iconClassName='ppt_export' /></span>*/}
                    {/*        <div className="tip-body tip-body--dark tip-body--gooey-menu-parent">*/}
                    {/*            <div className="tip-body__block-data">*/}
                    {/*                download segment profile*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*}*/}
                    {/*export menu*/}

                    <SummarySegments
                        spendTotals = {segmentsData.spend_totals}
                        unitsTotals = {segmentsData.units_totals}
                    />
                    <div>
                        <ShareOperators
                            shareOfOperatorSpend = {segmentsData.share_of_operator_spend}
                            segmentId={segmentId}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    access: state.landingpageReducer.access,
    segmentsList: state.segmentspageReducer.segmentsList,
    segmentsData: state.segmentspageReducer.segmentsData,
    notableOperators: state.segmentspageReducer.notableOperators,
    notableOperatorsTabs: state.segmentspageReducer.notableOperatorsTabs,
});

const mapDispatchToProps = dispatch => ({
    getData: (id) => dispatch(getData(id)),
    getSegments: () => dispatch(getSegments()),
    getAccess: () => dispatch(getAccess()),
    getNotableOperators: (segmentId, sort, sortType) => dispatch(getNotableOperators(segmentId, sort, sortType)),
    getNotableOperatorsTabs: (segmentId) => dispatch(getNotableOperatorsTabs(segmentId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ItemSegment);