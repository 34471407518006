import React, { Component, Fragment } from 'react';
import './style.scss';
import Icon from 'components/main/svg-component';
import { __fetch } from 'App';
import { API_EMAIL_SEND_URL } from "../../../core/constants";

export default class ModalAccess extends Component {
    constructor(state){
        super(state);
        this.state = {
            modalMessageVisible: true,
        };
        this.requestAccess = this.requestAccess.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    requestAccess() {
        this.setState({
            modalMessageVisible: false,
        });
        const searchUrl = `${API_EMAIL_SEND_URL}?target_url=${this.props.popupTarget}`;
       __fetch(searchUrl)
            .then(res => res.text())
            .then(res => {
                //console.log(res)
            })
        ;
    }
    closeModal() {
        this.setState({
            modalMessageVisible: true,
        });
        this.props.successModal();
    }
    render() {
        return (
            <Fragment>
                <div className={"overlay" + (this.props.modalVisible ? ' is-visible' : '')} onClick={this.closeModal.bind(this)}></div>
                <div className={"search-modal" + (this.props.modalVisible ? ' is-visible' : '')}>
                    <div className={"search-modal__text search-modal__message" + (this.state.modalMessageVisible ? ' is-visible' : '')}>
                        <div className="search-modal__body search-modal__body-text">
                            {this.props.popupType === 'snap' ?
                                this.props.popupText ?
                                    <div>{this.props.popupText}</div>
                                        :
                                        <div>Access menu data, location data, brand insights, and much more for {this.props.popupTargetName.toUpperCase()} with Datassential SNAP™.<br/><br/>Just press the button below to get started.</div>
                                        :
                                        <div>Access the profile, menu, competitive landscape, and much more for this operator with Datassential Firefly.<br/><br/>Just press the button below to get started.</div>
                            }
                        </div>
                        <div className="search-modal__buttons">
                            {this.props.popupType === 'snap' ? <span className="search-modal__logo"><Icon iconClassName='SNAP' /></span>
                                : <span className="search-modal__logo search-modal__logo--firefly"><Icon iconClassName='Firefly' /></span>}
                            <span className="search-modal__button" onClick={this.requestAccess.bind(this)}>Request access</span>
                        </div>
                    </div>
                    <div className={"search-modal__text search-modal__confirm" + (this.state.modalMessageVisible ? '' : ' is-visible')}>
                        <span className="search-modal__close" onClick={this.closeModal.bind(this)}><Icon iconClassName='remove' /></span>
                        <div className="search-modal__body search-modal__body-confirm">
                            <div>A Datassential professional will contact you within the next 24 hours to help get you started.<br/><br/>Enjoy!</div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}