import React, { Component, Fragment } from 'react';
import { connect, match } from 'react-redux';
import './style.scss';

import SwitchFilter from 'components/main/switch-filter';
import Table from 'components/main/table-general';
import Dropdown from 'components/main/dropdown';
import Icon from 'components/main/svg-component';
import PaginationTable from 'components/main/pagination-table';
import ModalAccess from 'components/main/modal-access';

import ifmaMarketsTotalUS from 'assets/images/top_chains_blurred.jpg';

import {
    getOperatorsData,
    updateChainAreaData
} from 'containers/Metro/metroActions';

class ChainArea extends Component {
    constructor(state) {
        super(state);
        this.state = {
            chainAreaSwitchParentId: 1,
            chainAreaSegment: 0,
            chainAreaMenuType: 0,
            chainAreaSegmentLabel: "All segments",
            chainAreaMenuTypeLabel: "All menu types",
            chainAreaDropdownsVisible: true,
            activePagechainArea: 1,
            chainAreaSort: 'count',
            chainAreaSortType: 'desc',
            searchFieldValue: '',
            searching: false,
            modalVisible: this.props.access.accessFirefly ? false : true,
            //modal access
            popupTargetName: 'Top Chains',
            popupTarget: 'Top Chains',
            popupType: 'firefly',
            ModalLoginVisible: false
        };
        this.tableSearch = this.tableSearch.bind(this);
        this.clickLink = this.clickLink.bind(this);
        this.successModal = this.successModal.bind(this);
        this.hideModalLogin = this.hideModalLogin.bind(this);
        this.updateContentAfterLogin = this.updateContentAfterLogin.bind(this);
    }
    componentDidMount() {
        const metroId = this.props.metroId;
        const type = this.props.type;
        this.props.getOperatorsData(metroId, type);
        window.scrollTo(0, 0);
    }
    tableSearch(event) {
        this.setState({
            searchFieldValue: event.target.value,
            searching: true
        }, function () {
            this.updateContent();
        });
    }
    changeChainArea(value, group) {
        if (value == 1) {
            this.setState({
                chainAreaDropdownsVisible: true,
                chainAreaSwitchParentId: value,
                activePagechainArea: 1
            }, function () {
                this.updateContent();
            });
        }  else {
            this.setState({
                chainAreaDropdownsVisible: false,
                chainAreaSwitchParentId: value,
                activePagechainArea: 1,
                chainAreaSegment: 0,
                chainAreaMenuType: 0,
            }, function () {
                this.updateContent();
            });
        }
    }

    changeChainAreaSegment(value, label) {
        this.setState({
            chainAreaSegment: value,
            chainAreaSegmentLabel: label,
            activePagechainArea: 1
        }, function () {
            this.updateContent();
        });
    }

    changeChainAreaMenuType(value, label) {
        this.setState({
            chainAreaMenuType: value,
            chainAreaMenuTypeLabel: label,
            activePagechainArea: 1
        }, function () {
            this.updateContent();
        });
    }

    changePage(page) {
        this.setState({
            activePagechainArea: page,
        }, function () {
            this.updateContent();
        });
    }

    sortingChainAreaTable(colKey) {
        const newChainAreaSortType = colKey !== this.state.chainAreaSort ? 'asc' : this.state.chainAreaSortType === 'desc' ? 'asc' : 'desc';
        this.setState({
            chainAreaSort: colKey,
            chainAreaSortType: newChainAreaSortType
        }, function () {
            this.updateContent();
        });
    }

    updateContent() {
        const metroId = this.props.metroId;
        const type = this.props.type;
        this.props.updateChainAreaData(
            metroId,
            type,
            this.state.activePagechainArea,
            this.state.chainAreaSwitchParentId,
            this.state.chainAreaSegment,
            this.state.chainAreaMenuType,
            this.state.chainAreaSort,
            this.state.chainAreaSortType,
            this.state.searchFieldValue
        );
    }
    clickLink(type, target, name) {
        this.setState({
            modalVisible: true,
            popupTargetName: name,
            popupTarget: target,
            popupType: type,
        });
    }

    successModal() {
        this.props.actionModal(0)
    }
    hideModalLogin(id) {
        this.props.actionModal(id)
    }
    updateContentAfterLogin() {
        this.updateContent();
    }

    render() {
        const chainAreaCopy = this.props.chainsArea && this.props.chainsArea;
        const chainAreaCopyTable = this.props.chainsArea && chainAreaCopy.table;
        const chainAreaCopyPagination = chainAreaCopyTable && chainAreaCopyTable.pagination.page_count;
        const activePagechainArea = this.state.activePagechainArea;
        const {metroCurrentId, metroId} = this.props;
        return (
            <Fragment>
                {this.props.access.accessFirefly && <Fragment>
                    <div className="ts-section-header">
                        <div className="ts-section-header__title ts-section-header__col">
                            <span className="ts-section-header--text-uppercase">Chains in the area</span>
                        </div>
                        <div className="ts-filter ts-section-header__col">
                            <div className="">
                                <span className="ts-filter__label">Show by</span>
                                <SwitchFilter
                                    switchData={chainAreaSwitch}
                                    group="chainAreaSwitch"
                                    prefixId="chainAreaSwitch"
                                    checked={this.state.chainAreaSwitchParentId}
                                    action={this.changeChainArea.bind(this)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="header-filters">
                        <div className="header-filters__col header-filters__col-full">
                            <div className="search-simple">
                                <div className="main-search__icon main-search__icon--right">
                                    <Icon iconClassName='search' />
                                    {/*{this.state.searching ? (<Icon iconClassName='oval' />) : (<Icon iconClassName='search' />)}*/}
                                </div>
                                <input
                                    className="search-simple__input"
                                    type="text"
                                    placeholder="Search by name"
                                    value={this.state.searchFieldValue}
                                    onChange={this.tableSearch}
                                />
                            </div>
                        </div>
                        {this.state.chainAreaDropdownsVisible &&
                        <Fragment>
                            <div className="header-filters__col header-filters__col-small">
                                <div className="ts-filter ts-filter--in-container ts-filter__container header-filters__inner">
                                    <span className="ts-filter__label ts-filter__label--left header-filters__inner-col">Segments</span>
                                    <div className="select-mod header-filters__inner-col">
                                        <Dropdown
                                            filtersList={this.props.chainsAreaSegments}
                                            orderByLabel={this.state.chainAreaSegmentLabel}
                                            action={this.changeChainAreaSegment.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="header-filters__col header-filters__col-small">
                                <div className="ts-filter ts-filter--in-container ts-filter__container header-filters__inner">
                                    <span className="ts-filter__label ts-filter__label--left header-filters__inner-col">Menu Type</span>
                                    <div className="select-mod header-filters__inner-col">
                                        <Dropdown
                                            filtersList={this.props.chainsRestaurantCuisines}
                                            orderByLabel={this.state.chainAreaMenuTypeLabel}
                                            action={this.changeChainAreaMenuType.bind(this)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                        }

                    </div>

                    <div className="ts-block__content-section relative">
                        <Table
                            tableData={metroCurrentId === metroId ? chainAreaCopyTable : null}
                            tableclassName=""
                            firstColClass="t-upper"
                            colclassName=""
                            hover={false}
                            sortingTableAction={this.sortingChainAreaTable.bind(this)}
                            tableSort={this.state.chainAreaSort}
                            tableSortType={this.state.chainAreaSortType}
                            clickLink={this.clickLink}
                        />

                        {chainAreaCopyPagination > 1 && <PaginationTable
                            tablePagination={chainAreaCopyPagination}
                            action={this.changePage.bind(this)}
                            activePagechainArea={activePagechainArea}
                        />}

                    </div>
                </Fragment>}


                {(this.props.access.accessNafem && !this.props.access.accessFirefly) && <div>
                    <img src={ifmaMarketsTotalUS} className=""/>
                    <ModalAccess
                        popupTargetName={this.state.popupTargetName}
                        popupTarget={this.state.popupTarget}
                        popupType={this.state.popupType}
                        modalVisible={this.state.modalVisible}
                        successModal={this.successModal.bind(this)}
                    />
                </div>}
            </Fragment>
        )
    }
}

const chainAreaSwitch = [
    {
        title: 'Restaurants',
        value: 1
    },
    {
        title: 'C-Stores',
        value: 21
    },
    {
        title: 'Supermarkets',
        value: 23
    },
    {
        title: 'Hotels',
        value: 26
    }
];

const mapStateToProps = state => ({
    access: state.landingpageReducer.access,
    metroCurrentId: state.metropageReducer.metroCurrentId,
    chainsArea: state.metroOperatorReducer.chainsArea,
    chainsAreaSegments: state.metroOperatorReducer.chainsAreaSegments,
    chainsRestaurantCuisines: state.metroOperatorReducer.chainsRestaurantCuisines,
});

const mapDispatchToProps = dispatch => ({
    getOperatorsData: (propId, type, chainAreaSwitchParentId) => dispatch(getOperatorsData(propId, type, chainAreaSwitchParentId)),
    updateChainAreaData: (
        propId,
        type,
        page,
        chainAreaSwitchParentId,
        chainAreaSegment,
        chainAreaMenuType,
        chainAreaSort,
        chainAreaSortType,
        name
    ) => dispatch(updateChainAreaData(
        propId,
        type,
        page,
        chainAreaSwitchParentId,
        chainAreaSegment,
        chainAreaMenuType,
        chainAreaSort,
        chainAreaSortType,
        name
    )),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChainArea);