import React, { Component, Fragment, PropTypes } from 'react';
import { connect, match } from 'react-redux';
import Page500 from 'components/main/page500';

export default class Error500 extends Component {

    constructor(state) {
        super(state);
    }

    render() {
        return (
            <Fragment>
                <div className="wrap">
                    <Page500 />
                </div>
            </Fragment>
        );
    }
}