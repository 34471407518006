import React, { Component } from 'react';
import { getIcon } from "../../../core/icons";

export default class Icon extends Component {
    render() {
        const iconClassName = this.props.iconClassName;
        const Icon = getIcon(iconClassName);
        return(
            <Icon
                width="1em"
                height="1em"
                className="dib v-mid"
            />
        )
    }
}