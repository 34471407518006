import {
    SEGMENTS_GET_LIST,
    SEGMENTS_GET_DATA,
    SEGMENTS_GET_NOTABLE_OPERATORS,
    SEGMENTS_GET_NOTABLE_OPERATORS_TABS
} from './segmentsActionTypes';
import Http from 'helpers/http';
import { API_FF, API_URL } from "../../core/constants";

export function getSegments() {
    return (dispatch) => {
        return Http.get(`${API_FF}/segments/tree`).then((data) => {
            dispatch(segmentsData(data));
        });
    };
}

export function getData(id) {
    return (dispatch) => {
        Http.get(`${API_URL}/segments/default/view/${id}`).then((data) => {
            dispatch(storeData(data));
        });
    };
}
export function getNotableOperators(id, sort, sortType) {
    return (dispatch) => {
        Http.get(`${API_URL}/segments/default/notable-operators/${id}`, {
            sort: sort,
            sort_type: sortType,
        }).then((data) => {
            dispatch(notableOperatorsData(data));
        });
    };
}
export function getNotableOperatorsTabs(id) {
    return (dispatch) => {
        Http.get(`${API_URL}/segments/default/get-notable-operator-tabs/${id}`).then((data) => {
            dispatch(notableOperatorsTabs(data));
        });
    };
}

function segmentsData(data) {
    return {
        type: SEGMENTS_GET_LIST,
        data
    }
}
function storeData(data) {
    return {
        type: SEGMENTS_GET_DATA,
        data
    }
}
function notableOperatorsData(data) {
    return {
        type: SEGMENTS_GET_NOTABLE_OPERATORS,
        data
    }
}
function notableOperatorsTabs(data) {
    return {
        type: SEGMENTS_GET_NOTABLE_OPERATORS_TABS,
        data
    }
}