import React, { Component, Fragment } from 'react';
import './style.scss';

export default class SummarySegments extends Component {
    constructor(state) {
        super(state);
        this.state = {};
    }
    render() {
        const {spendTotals, unitsTotals} = this.props;
        const listLength = (spendTotals && Object.keys(spendTotals).length) + (unitsTotals && Object.keys(unitsTotals).length);
        return (
            <Fragment>
                <div className="ts-block__content-section ts-block__content-section--padd-t-0 border-bottom">
                    <div className="i-row i-row--wrap summary-segments">
                        {spendTotals && spendTotals.map(function (item, index) {
                            return <div className={"i-col-1-" + listLength + " summary-segments__col"} key={index}>
                                        <div className="summary-segments__data is-green">{item.value}</div>
                                        <div className="summary-segments__title">{item.title}</div>
                                        <div className="summary-segments__caption is-green">{item.hint}</div>
                                    </div>
                        })}
                        {unitsTotals && unitsTotals.map(function (item, index) {
                            return <div className={"i-col-1-" + listLength + " summary-segments__col"} key={index}>
                                <div className="summary-segments__data is-blue">{item.value}</div>
                                <div className="summary-segments__title">{item.title}</div>
                                <div className="summary-segments__caption is-blue">{item.hint}</div>
                            </div>
                        })}
                    </div>
                </div>
            </Fragment>
        )
    }
}