import {
    LANDING_ACCESS_CREDENTIALS,
    LANDING_ACCESS_NAFEM,
    LANDING_ACCESS_SNAP,
    LANDING_ACCESS_FIREFLY,
    LANDING_ACCESS_EMAIL,
    LANDING_ACCESS_IS_FIRST
} from './landingActionTypes';


const defaultState = {
    //access
    access: {
        accessCredentials: [],
        accessNafem: false,
        accessSnap: false,
        accessFirefly: false,
        accessEmail: '',
        accessIsFirst: false
    }
};

export default function homeReducer(state = defaultState, action) {
    switch (action.type) {
        case LANDING_ACCESS_CREDENTIALS: {
            return {
                ...state,
                access: {
                    ...state.access,
                    accessCredentials: action.data,
                }
            }
        }
        case LANDING_ACCESS_NAFEM: {
            return {
                ...state,
                access: {
                    ...state.access,
                    accessNafem: action.data,
                }
            }
        }
        case LANDING_ACCESS_SNAP: {
            return {
                ...state,
                access: {
                    ...state.access,
                    accessSnap: action.data,
                }
            }
        }
        case LANDING_ACCESS_FIREFLY: {
            return {
                ...state,
                access: {
                    ...state.access,
                    accessFirefly: action.data,
                }
            }
        }
        case LANDING_ACCESS_EMAIL: {
            return {
                ...state,
                access: {
                    ...state.access,
                    accessEmail: action.data,
                }
            }
        }
        case LANDING_ACCESS_IS_FIRST: {
            return {
                ...state,
                access: {
                    ...state.access,
                    accessIsFirst: action.data,
                }
            }
        }
        default: {
            return state;
        }
    }
}
