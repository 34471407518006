import React, { Component, Fragment } from 'react';
import './style.scss';
import { __fetch } from 'App';
import { MAP_API_BORDERS, MAP_API_COORDINATES, MAP_API_INITIAL_BORDERS, MAP_API_LAYERS } from "core/constants";

const google = window.google;

export default class Map extends Component {
    constructor(state){
        super(state);
        this.state = {
            mapInitialBorders: {}
        };
        this.getMapBorders = this.getMapBorders.bind(this);
    }
    componentDidMount() {
        this.getMapBorders();
    }
    getMapBorders() {
        const _this = this;
        _this.setState({
            mapInitialBorders: {}
        }, function () {
            const metroId = this.props.metroId;
            const regionType = this.props.regionType;
            const link = `${MAP_API_INITIAL_BORDERS}?${[regionType]}=${metroId}`;
           __fetch(link)
                .then(res => res.json())
                .then(data => {
                    _this.setState({
                        mapInitialBorders: data
                    });
                })
                .catch(err => {
                    if (err.name === 'AbortError') {
                        //console.log('Fetch aborted');
                    }
                })
            ;
        });

    }
    render() {
        const {
            metroId,
            marketStructureActive
        } = this.props;
        const regionType = this.props.regionType == 'metro' ? 'DMA' : this.props.regionType;
        const mapCanvas = document.getElementById('map_canvas');
        const mapInitialBorders = this.state.mapInitialBorders;
        if(mapCanvas !== null && Object.keys(mapInitialBorders).length > 0) {
            mapInitialBorders && mapWithMarkersOverlay.initCanvas(mapCanvas, {
                zoom: this.state.mapZoom,
                loadLayerUrl: MAP_API_LAYERS,
                loadBordersUrl: MAP_API_BORDERS,
                mapClickUrl: MAP_API_COORDINATES,
                borders: mapInitialBorders
            },
                marketStructureActive,
                metroId,
                regionType
            );
        }

        return (
            <Fragment>
                <div id="map_canvas"
                     className="map"
                     style={{
                         //width: `calc((500 / 1920) * 100vw)`,
                         height: `calc((500 / 1920) * 100vw)`
                     }}
                     //style={{width: '500px', height: '500px'}}
                ></div>
            </Fragment>
        )
    }
}

let controller;
let signal;
let layerController;
let layerSignal;

const mapWithMarkersOverlay = {
    options: {
        loadLayerUrl: '',
        loadBordersUrl: '',
        mapClickUrl: '',
        lng: -97,
        lat: 38,
        borders: {},
        zoom: 4,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [
            {featureType: 'administrative', elementType: 'labels.text.fill', stylers:[{color: '#444444'}]},
            {featureType: 'landscape', elementType: 'all', stylers:[{color: '#f2f2f2'}]},
            {featureType: 'poi', elementType: 'all', stylers:[{visibility: 'off'}]},
            {featureType: 'road', elementType: 'all', stylers:[{saturation: -100},{lightness: 45}]},
            {featureType: 'road.highway', elementType: 'all', stylers:[{visibility: 'simplified'}]},
            {featureType: 'road.arterial', elementType: 'labels.icon', stylers:[{visibility: 'off'}]},
            {featureType: 'transit', elementType: 'all', stylers:[{visibility: 'off'}]},
            {featureType: 'water', elementType: 'all', stylers:[{color: '#46bcec'},{visibility: 'on'}]}
        ]
    },
    baseBorders: {
        north: 75,
        south: 15,
        east: -50,
        west: -180
    },
    map: null,
    startBounds: null,
    bounds: null,
    infoWindow: null,
    markers: [],
    canvasLayer: null,
    loadLayerUrl: null,
    loadBordersUrl: null,
    mapClickUrl: null,
    firstTime: true,
    layers: [],
    borders: [],
    markerSize: 0,
    baseBlock: null,
    _timer: null,
    _requestDelay: 250,
    marketStructureActive: null,
    metroId: null,
    changeZoom: null,

    _destroy: function() {
        document.getElementsByClassName("layers-block").remove();
        ImagesCache.destroy();
    },
    initCanvas: function (baseBlock, params, marketStructureActive, metroId, regionType) {
        const self = this;
        self.loadLayerUrl = params.loadLayerUrl;
        self.loadBordersUrl = params.loadBordersUrl;
        self.mapClickUrl = params.mapClickUrl;

        self.layers = [];
        self.borders = [];
        self.startBounds = null;
        self.marketStructureActive = marketStructureActive;
        self.metroId = metroId;
        self.regionType = regionType;

        this._initMap(baseBlock, params);
        this._addBlockForLayers();
        ImagesCache.init();

        self.clearCanvas();
        google.maps.event.trigger(self.map, 'idle');
        self._mapClickEvent();
        self._boundsChangeEvent();
    },
    _initMap: function(baseBlock, params) {
        const self = this;
        const center = new google.maps.LatLng(self.options.lat, self.options.lng);

        const googleOptions = {
            minZoom: 2,
            // zoom: self.options.zoom,
            center: center,
            draggableCursor: 'pointer',
            disableAutoPan: true,
            mapTypeId: self.options.mapTypeId,
            styles: self.options.styles,
            disableDefaultUI: true,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_TOP
            },
        };
        self.options.loadLayerUrl = params.loadLayerUrl;
        self.options.loadBordersUrl = params.loadBordersUrl;
        self.options.mapClickUrl = params.mapClickUrl;
        self.options.borders = params.borders;
        // self.options.zoom = params.zoom;

        self.baseBlock = baseBlock;
        self.map = new google.maps.Map(self.baseBlock, googleOptions);
        if (Object.keys(self.options.borders).length === 4) {
            const sw = new google.maps.LatLng(
                self.options.borders.south,
                self.options.borders.west
            );
            const ne = new google.maps.LatLng(
                self.options.borders.north,
                self.options.borders.east
            );
            const bounds = new google.maps.LatLngBounds(sw, ne);
            self.map.fitBounds(bounds);
        }
        self.infoWindow = new google.maps.InfoWindow();
    },
    _onLayerLoadEvent: function () {
        const self = this;
        const img = document.getElementsByClassName("js-layer");
        let i;
        for (i = 0; i < img.length; i++) {
            img[i].onload = function() {
                const dataKey = this.getAttribute('data-key');
                if (self.layers[dataKey] !== null) {
                    self._drawImage(dataKey, self.layers[dataKey].borders);
                }
            };
        }
    },
    _addBlockForLayers: function () {
        this.baseBlock.insertAdjacentHTML('beforeend', '<div style="display: none" id="layers-block"></div>');
        let i;
        for (i = 0; i < 4; i++) {
            document.getElementById('layers-block').insertAdjacentHTML('beforeend', '<img src="" alt="" class="js-layer" data-key="' + i + '">');
        }
        this._onLayerLoadEvent();
    },
    _mapClickEvent: function () {
        const self = this;
        google.maps.event.addListener(self.map, 'click', function(position) {
            let data = {
                lat: position.latLng.lat(),
                lng: position.latLng.lng(),
                zoom: self.map.getZoom(),
                region_type: self.regionType,
                region_value: self.metroId,
                parent_segment: self.marketStructureActive
            };
           __fetch(self.mapClickUrl, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data)
            })
            .then(res => res.json())
            .then(response => {
                const locationData = response['data'];
                if(locationData !== undefined) {
                    const infoWindowHtml = self._getInfoWindowHtml(locationData);
                    const position = new google.maps.LatLng(locationData['latitude'], locationData['longitude']);
                    self.infoWindow.setPosition(position);
                    self.infoWindow.open(self.map);
                    self.infoWindow.setContent(infoWindowHtml);
                }
            })
            .then(response => {
                self._customViewPopUp();
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log('cancel borders load');
                }
            })
        });
    },
    _getInfoWindowHtml: function (locationData) {
        if(locationData !== undefined) {
            return '' +
                '<div class="map-info" style="border-color:' + locationData.color + '">' +
                '<div class="map-info__title">' +
                '<span class="map-info__label">Firefly ID:</span> ' + locationData.ff_id +
                '</div>' +
                '<div class="map-info__description">' + locationData.description + '</div>' +
                '<div class="map-info__address">' + locationData.address + '</div>' +
                '<div class="map-info__phone">' + locationData.phone + '</div>' +
                '<div class="map-info__footer clearfix">' +
                //'<a href="' + locationData.url + '" target="_blank" title="location page">' +
                '<div class="map-info__name" style="color:' + locationData.color + '">' +
                locationData.name +
                '</div>' +
                //'</a>' +
                '<div class="map-info__parent-segment">' +
                '<span class="map-info__dot" style="background-color:' + locationData.color + '"></span>'
                + locationData.parent_segment_name +
                '</div>' +
                '</div>' +
                '</div>';
        }
    },
    _customViewPopUp: function () {
        const contentOut = document.getElementsByClassName("gm-style-iw");
    },
    _setCanvasStartParameters: function () {
        const self = this;
        let context = self.canvasLayer.canvas.getContext('2d');
        context.setTransform(1, 0, 0, 1, 0, 0);
        const resolutionScale = window.devicePixelRatio || 1;
        context.scale(resolutionScale, resolutionScale);
        context.imageSmoothingEnabled = false;
        // self.map.setZoom(self.options.zoom)
    },
    _boundsChangeEvent: function () {
        const self = this;
        google.maps.event.addListener(self.map, "idle", function() {
            self.bounds = self.map.getBounds();

            if (self.startBounds === null) {
                self.startBounds = self.map.getBounds();
                const resolutionScale = window.devicePixelRatio || 1;
                const canvasLayerOptions = {
                    map: self.map,
                    resizeHandler: self._resizeCanvas,
                    animate: false,
                    updateHandler: self._updateCanvas,
                    resolutionScale: resolutionScale,
                    mapWidgetClass: self
                };
                self.canvasLayer = new CanvasLayer(canvasLayerOptions);
                self._setCanvasStartParameters();
            }

            if (self.layers.length === 0 || self._mapOutOfBorders()) {
                self.updateLayers();
            }
        });
    },
    _sendRequestWithDelay: function () {
        const self = this;
        self._clearTimeout();
        self._timer = setTimeout(function () {
            self.updateLayers();
        }, self._requestDelay);
    },
    _clearTimeout: function () {
        if (this._timer !== null) {
            clearTimeout(this._timer);
        }
    },
    _zoomChangeEvent: function () {
        const self = this;

        google.maps.event.addListener(self.map, "zoom_changed", function() {
            self.baseBlock.getElementsByClassName("js-layer")[0].setAttribute('src', '');
            self.baseBlock.getElementsByClassName("js-layer")[1].setAttribute('src', '');
            self.baseBlock.getElementsByClassName("js-layer")[2].setAttribute('src', '');
            self.baseBlock.getElementsByClassName("js-layer")[3].setAttribute('src', '');
            self.clearCanvas();
            self._sendRequestWithDelay();
        });
    },
    updateLayers: function () {
        const self = this;
        const data = {
            zoom: self.map.getZoom(),
            center: {lng: self.map.getCenter().lng(), lat: self.map.getCenter().lat()},
            width: self.baseBlock.offsetWidth,
            height : self.baseBlock.offsetHeight,
        };

        if (data.width > 0 && data.height > 0) {
            if (controller !== undefined) {
                // Cancel the previous request
                controller.abort();
            }
            controller = new AbortController;
            signal = controller.signal;

           __fetch(self.loadBordersUrl, {
                method: 'POST',
                signal: signal,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    zoom: self.map.getZoom(),
                    center: {lng: self.map.getCenter().lng(), lat: self.map.getCenter().lat()},
                    width: self.baseBlock.offsetWidth,
                    height : self.baseBlock.offsetHeight,
                })
            })
            .then(res => res.json())
            .then(response => {
                self.clearCanvas();
                self.borders = response.commonBorders;
                self.markerSize = response.markerSize;
                self._reloadLayers(response, data);
                self._zoomChangeEvent();
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log('cancel borders load');
                }
            })
        }
    },
    _reloadLayers: function (response, data) {
        const self = this;
        for (let i in layerController) {
            // Cancel the previous request
            layerController[i].abort();
        }

        layerController = [];

        for (let i = 0; i < 4; i++) {
            const currentLayer = self.baseBlock.getElementsByClassName("js-layer")[i];
            if (typeof response.borders[i] !== 'undefined') {
                const params = {
                    zoom: self.map.getZoom(),
                    borders: response.borders[i],
                    region_type: self.regionType,
                    region_value: self.metroId,
                    parent_segment: self.marketStructureActive
                };
                let parameters = params;

                const key = self._hashCode(parameters);

                self.layers[i] = {
                    key: key,
                    borders: response.borders[i]
                };

                self._loadImage(currentLayer, parameters, key, i, params);
            } else {
                self.layers[i] = null;
            }
        }
    },
    _hashCode: function(string){
        let hash = 0;
        if (this.length === 0) return hash;
        for (let i = 0; i < string.length; i++) {
            const char = string.charCodeAt(i);
            hash = ((hash<<5)-hash)+char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    },
    _loadImage: function (currentLayer, parameters, key, i) {
        const self = this;
        const value = ImagesCache.get(key);
        if (value !== false) {
            currentLayer.setAttribute('src', value);
        } else {
            layerController[i] = new AbortController;
            layerSignal = layerController[i].signal;
           __fetch(self.loadLayerUrl, {
                method: 'POST',
                signal: layerSignal,
                headers: {
                    Accept: 'application/json, application/xml, text/plain, text/html, */*',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(parameters)
            })
            .then(response => response.text())
            .then(body => {
                ImagesCache.set(key, body);
                if (self.layers[i] !== undefined && self.layers[i] !== null && key === self.layers[i].key) {
                    currentLayer.setAttribute('src', body);
                }
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.log('cancel layer load');
                }
            })
        }
    },
    _resizeCanvas: function () {
        // nothing to do here
    },
    _updateCanvas: function () {
        this.getMapWidgetClass()._drawImages();
    },
    _mapOutOfBorders: function () {
        const self = this;
        if (self.bounds.getNorthEast().lat() > self.borders.north && self.borders.north < self.baseBorders.north) {
            return true;
        }
        if (self._normalizeLongitude(self.bounds.getNorthEast().lng()) > self.borders.east && self.borders.east < self.baseBorders.east) {
            return true;
        }
        if (self.bounds.getSouthWest().lat() < self.borders.south && self.borders.south > self.baseBorders.south) {
            return true;
        }
        if (self._normalizeLongitude(self.bounds.getSouthWest().lng()) < self.borders.west && self.borders.west > self.baseBorders.west) {
            return true;
        }
        return false;
    },
    _normalizeLongitude: function (longitude) {
        const self = this;
        if (longitude > self.baseBorders.south) {
            longitude -= 360;
        }
        return longitude;
    },
    _drawImages: function () {
        const self = this;
        self.clearCanvas();
        for (let i = 0; i < self.layers.length; i++) {
            if (self.layers[i] !== null) {
                self._drawImage(i, self.layers[i].borders);
            }
        }
    },
    clearCanvas: function () {
        const self = this;
        if (self.canvasLayer !== null) {
            const context = self.canvasLayer.canvas.getContext('2d');
            const canvasWidth = self.canvasLayer.canvas.width;
            const canvasHeight = self.canvasLayer.canvas.height;
            context.clearRect(0, 0, canvasWidth, canvasHeight);
            context.fillStyle = 'rgba(230, 77, 26, 1)';
        }
    },

    _drawImage: function (layer_id, borders) {
        const self = this;
        if (self.canvasLayer !== null) {
            const imageContainer = self.baseBlock.getElementsByClassName("js-layer")[layer_id];
            drawLayer.init(self.canvasLayer, imageContainer, borders, self.markerSize);
            drawLayer.draw();
        }
    },
    getMap: function () {
        return this.map;
    },
    removePopUpWindow: function () {
        document.getElementsByClassName("gm-style-iw").previousSibling.remove();
        document.getElementsByClassName("map-info").remove();
        document.getElementsByClassName("fa-close").remove();
    },
    clearStartBounds: function () {
        this.startBounds = null;
    },
};

const drawLayer = {
    canvasLayer: null,
    context: null,
    image: null,
    borders: null,
    deltaX: null,
    deltaY: null,
    markerSize: 0,
    init: function (canvasLayer, imageContainer, borders, markerSize) {
        const self = this;
        self.canvasLayer = canvasLayer;
        self.image = imageContainer;
        self.borders = borders;
        self.context = self.canvasLayer.canvas.getContext('2d');
        self.context.imageSmoothingEnabled = false;
        self.markerSize = markerSize;
    },
    draw: function () {
        const self = this;
        self._calculateTopLeftCorner();
        self._drawImage();
    },
    _calculateTopLeftCorner: function () {
        const self = this;
        const mapProjection = self.canvasLayer.map.getProjection();

        const offset = mapProjection.fromLatLngToPoint(self.canvasLayer.getTopLeft());
        const layerTopLeft = new google.maps.LatLng(
            self.borders.north,
            self.borders.west
        );
        const southEast = new google.maps.LatLng(
            self.borders.south,
            self.borders.east
        );

        const southEastPoint = mapProjection.fromLatLngToPoint(southEast);
        const layerTopLeftPoint = mapProjection.fromLatLngToPoint(layerTopLeft);

        if (southEastPoint.x < offset.x) {
            offset.x -= 256;
        }

        const baseScale = Math.pow(2, self.canvasLayer.map.zoom);
        self.deltaX = Math.round((layerTopLeftPoint.x - offset.x)*baseScale - self.markerSize/2);
        self.deltaY = Math.round((layerTopLeftPoint.y - offset.y)*baseScale - self.markerSize/2);
    },
    _drawImage: function () {
        const self = this;
        const canvasWidth = self.canvasLayer.canvas.width;
        const canvasHeight = self.canvasLayer.canvas.height;
        const resolutionScale = window.devicePixelRatio || 1;
        const sx = self._normalizeCorner(self.deltaX);
        const sy = self._normalizeCorner(self.deltaY);
        const sWidth = self._normalizeDimension(self.deltaX, canvasWidth/resolutionScale, self.image.naturalWidth);
        const sHeight = self._normalizeDimension(self.deltaY, canvasHeight/resolutionScale, self.image.naturalHeight);
        const x = self._normalizeTarget(self.deltaX);
        const y = self._normalizeTarget(self.deltaY);

        if (sWidth > 0 && sHeight > 0) {
            self.context.drawImage(self.image, sx, sy, sWidth, sHeight, resolutionScale*x, resolutionScale*y, resolutionScale*sWidth, resolutionScale*sHeight);
        }
    },
    _normalizeCorner: function (coordinate) {
        if (coordinate > 0) {
            return 0;
        } else {
            return -coordinate;
        }
    },
    _normalizeDimension: function (coordinate, canvasDimensionSize, imageDimensionSize) {
        const self = this;
        if (coordinate > 0) {
            return Math.min(canvasDimensionSize - coordinate + self.markerSize, imageDimensionSize);
        } else {
            return Math.min(canvasDimensionSize + coordinate + self.markerSize, imageDimensionSize + coordinate);
        }
    },
    _normalizeTarget: function (coordinate) {
        if (coordinate > 0) {
            return coordinate;
        } else {
            return 0;
        }
    }
};

const ImagesCache = {
    init: function() {
        document.getElementsByTagName("body")[0].insertAdjacentHTML('beforeend', '<div id="layer_images_cache" style="display: none"></div>');
    },
    destroy: function () {
        document.getElementById("layer_images_cache").remove();
    },
    set: function (key, value) {
        const image = document.getElementById('layer_image_' + key);
        if (image !== null && image.length === 0) {
            document.getElementById('layer_images_cache').insertAdjacentHTML('beforeend', '<img src="' + value + '" id="layer_image_' + key + '">');
        }
    },
    get: function (key) {
        const image = document.getElementById('layer_image_' + key);
        if (image !== null && image.length > 0) {
            return image.src;
        }
        return false;
    }
};


//from canvas layer options
/**
 * Copyright 2012 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Definitions of all options for CanvasLayer.
 * @author Brendan Kenny
 */

/**
 * Options for a CanvasLayer.
 *
 * @interface
 */
function CanvasLayerOptions() {}

/**
 * If true, updateHandler will be called repeatedly, once per frame. If false,
 * updateHandler will only be called when a map property changes that could
 * require the canvas content to be redrawn.
 * @type {boolean}
 */
// eslint-disable-next-line
CanvasLayerOptions.prototype.animate;

/**
 * Map on which to overlay the canvas.
 * @type {google.maps.Map}
 */
// eslint-disable-next-line
CanvasLayerOptions.prototype.map;

/**
 * The name of the MapPane in which this layer will be displayed. See
 * {@code google.maps.MapPanes} for the panes available. Default is
 * "overlayLayer".
 * @type {string}
 */
// eslint-disable-next-line
CanvasLayerOptions.prototype.paneName;

/**
 * A function that is called whenever the canvas has been resized to fit the
 * map.
 * @type {function}
 */
// eslint-disable-next-line
CanvasLayerOptions.prototype.resizeHandler;

/**
 * A value for scaling the CanvasLayer resolution relative to the CanvasLayer
 * display size. This can be used to save computation by scaling the backing
 * buffer down, or to support high DPI devices by scaling it up (by e.g.
 * window.devicePixelRatio).
 * @type {number}
 */
// eslint-disable-next-line
CanvasLayerOptions.prototype.resolutionScale;

/**
 * A function that is called when a repaint of the canvas is required.
 * @type {function}
 */
// eslint-disable-next-line
CanvasLayerOptions.prototype.updateHandler;


//from canvas layer
/**
 * Copyright 2012 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Extends OverlayView to provide a canvas "Layer".
 * @author Brendan Kenny
 */

/**
 * A map layer that provides a canvas over the slippy map and a callback
 * system for efficient animation. Requires canvas and CSS 2D transform
 * support.
 * @constructor
 * @extends google.maps.OverlayView
 * @param {CanvasLayerOptions=} opt_options Options to set in this CanvasLayer.
 */
function CanvasLayer(opt_options) {
    /**
     * If true, canvas is in a map pane and the OverlayView is fully functional.
     * See google.maps.OverlayView.onAdd for more information.
     * @type {boolean}
     * @private
     */
    this.isAdded_ = false;

    /**
     * If true, each update will immediately schedule the next.
     * @type {boolean}
     * @private
     */
    this.isAnimated_ = false;

    /**
     * The name of the MapPane in which this layer will be displayed.
     * @type {string}
     * @private
     */
    this.paneName_ = CanvasLayer.DEFAULT_PANE_NAME_;

    /**
     * A user-supplied function called whenever an update is required. Null or
     * undefined if a callback is not provided.
     * @type {?function=}
     * @private
     */
    this.updateHandler_ = null;

    /**
     * A user-supplied function called whenever an update is required and the
     * map has been resized since the last update. Null or undefined if a
     * callback is not provided.
     * @type {?function}
     * @private
     */
    this.resizeHandler_ = null;

    /**
     * The LatLng coordinate of the top left of the current view of the map. Will
     * be null when this.isAdded_ is false.
     * @type {google.maps.LatLng}
     * @private
     */
    this.topLeft_ = null;

    /**
     * The map-pan event listener. Will be null when this.isAdded_ is false. Will
     * be null when this.isAdded_ is false.
     * @type {?function}
     * @private
     */
    this.centerListener_ = null;

    /**
     * The map-resize event listener. Will be null when this.isAdded_ is false.
     * @type {?function}
     * @private
     */
    this.resizeListener_ = null;

    /**
     * If true, the map size has changed and this.resizeHandler_ must be called
     * on the next update.
     * @type {boolean}
     * @private
     */
    this.needsResize_ = true;

    /**
     * A browser-defined id for the currently requested callback. Null when no
     * callback is queued.
     * @type {?number}
     * @private
     */
    this.requestAnimationFrameId_ = null;

    const canvas = document.createElement('canvas');
    canvas.style.position = 'absolute';
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.pointerEvents = 'none';

    /**
     * The canvas element.
     * @type {!HTMLCanvasElement}
     */
    this.canvas = canvas;

    /**
     * The CSS width of the canvas, which may be different than the width of the
     * backing store.
     * @private {number}
     */
    this.canvasCssWidth_ = 300;

    /**
     * The CSS height of the canvas, which may be different than the height of
     * the backing store.
     * @private {number}
     */
    this.canvasCssHeight_ = 150;

    /**
     * A value for scaling the CanvasLayer resolution relative to the CanvasLayer
     * display size.
     * @private {number}
     */
    this.resolutionScale_ = 1;

    /**
     * Simple bind for functions with no args for bind-less browsers (Safari).
     * @param {Object} thisArg The this value used for the target function.
     * @param {function} func The function to be bound.
     */
    function simpleBindShim(thisArg, func) {
        return function () {
            func.apply(thisArg);
        };
    }

    /**
     * A reference to this.repositionCanvas_ with this bound as its this value.
     * @type {function}
     * @private
     */
    this.repositionFunction_ = simpleBindShim(this, this.repositionCanvas_);

    /**
     * A reference to this.resize_ with this bound as its this value.
     * @type {function}
     * @private
     */
    this.resizeFunction_ = simpleBindShim(this, this.resize_);

    /**
     * A reference to this.update_ with this bound as its this value.
     * @type {function}
     * @private
     */
    this.requestUpdateFunction_ = simpleBindShim(this, this.update_);

    this.MapWidgetClass_ = null;

    // set provided options, if any
    if (opt_options) {
        this.setOptions(opt_options);
    }
}

CanvasLayer.prototype = new google.maps.OverlayView();

/**
 * The default MapPane to contain the canvas.
 * @type {string}
 * @const
 * @private
 */
CanvasLayer.DEFAULT_PANE_NAME_ = 'overlayLayer';

/**
 * Transform CSS property name, with vendor prefix if required. If browser
 * does not support transforms, property will be ignored.
 * @type {string}
 * @const
 * @private
 */
CanvasLayer.CSS_TRANSFORM_ = (function () {
    const div = document.createElement('div');
    const transformProps = [
        'transform',
        'WebkitTransform',
        'MozTransform',
        'OTransform',
        'msTransform'
    ];
    for (let i = 0; i < transformProps.length; i++) {
        const prop = transformProps[i];
        if (div.style[prop] !== undefined) {
            return prop;
        }
    }

    // return unprefixed version by default
    return transformProps[0];
})();

/**
 * The requestAnimationFrame function, with vendor-prefixed or setTimeout-based
 * fallbacks. MUST be called with window as thisArg.
 * @type {function}
 * @param {function} callback The function to add to the frame request queue.
 * @return {number} The browser-defined id for the requested callback.
 * @private
 */
CanvasLayer.prototype.requestAnimFrame_ =
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function (callback) {
        return window.setTimeout(callback, 1000 / 60);
    };

/**
 * The cancelAnimationFrame function, with vendor-prefixed fallback. Does not
 * fall back to clearTimeout as some platforms implement requestAnimationFrame
 * but not cancelAnimationFrame, and the cost is an extra frame on onRemove.
 * MUST be called with window as thisArg.
 * @type {function}
 * @param {number=} requestId The id of the frame request to cancel.
 * @private
 */
CanvasLayer.prototype.cancelAnimFrame_ =
    window.cancelAnimationFrame ||
    window.webkitCancelAnimationFrame ||
    window.mozCancelAnimationFrame ||
    window.oCancelAnimationFrame ||
    window.msCancelAnimationFrame ||
    function (requestId) {
    };

/**
 * Sets any options provided. See CanvasLayerOptions for more information.
 * @param {CanvasLayerOptions} options The options to set.
 */
CanvasLayer.prototype.setOptions = function (options) {
    if (options.animate !== undefined) {
        this.setAnimate(options.animate);
    }

    if (options.paneName !== undefined) {
        this.setPaneName(options.paneName);
    }

    if (options.updateHandler !== undefined) {
        this.setUpdateHandler(options.updateHandler);
    }

    if (options.resizeHandler !== undefined) {
        this.setResizeHandler(options.resizeHandler);
    }

    if (options.resolutionScale !== undefined) {
        this.setResolutionScale(options.resolutionScale);
    }

    if (options.map !== undefined) {
        this.setMap(options.map);
    }

    if (options.mapWidgetClass !== undefined) {
        this.setMapWidgetClass(options.mapWidgetClass);
    }
};

CanvasLayer.prototype.setMapWidgetClass = function (MapWidgetClass) {
    this.MapWidgetClass_ = MapWidgetClass;
};

CanvasLayer.prototype.getMapWidgetClass = function () {
    return this.MapWidgetClass_;
};

/**
 * Set the animated state of the layer. If true, updateHandler will be called
 * repeatedly, once per frame. If false, updateHandler will only be called when
 * a map property changes that could require the canvas content to be redrawn.
 * @param {boolean} animate Whether the canvas is animated.
 */
CanvasLayer.prototype.setAnimate = function (animate) {
    this.isAnimated_ = !!animate;

    if (this.isAnimated_) {
        this.scheduleUpdate();
    }
};

/**
 * @return {boolean} Whether the canvas is animated.
 */
CanvasLayer.prototype.isAnimated = function () {
    return this.isAnimated_;
};

/**
 * Set the MapPane in which this layer will be displayed, by name. See
 * {@code google.maps.MapPanes} for the panes available.
 * @param {string} paneName The name of the desired MapPane.
 */
CanvasLayer.prototype.setPaneName = function (paneName) {
    this.paneName_ = paneName;

    this.setPane_();
};

/**
 * @return {string} The name of the current container pane.
 */
CanvasLayer.prototype.getPaneName = function () {
    return this.paneName_;
};

/**
 * Adds the canvas to the specified container pane. Since this is guaranteed to
 * execute only after onAdd is called, this is when paneName's existence is
 * checked (and an error is thrown if it doesn't exist).
 * @private
 */
CanvasLayer.prototype.setPane_ = function () {
    if (!this.isAdded_) {
        return;
    }

    // onAdd has been called, so panes can be used
    const panes = this.getPanes();
    if (!panes[this.paneName_]) {
        throw new Error('"' + this.paneName_ + '" is not a valid MapPane name.');
    }

    panes[this.paneName_].appendChild(this.canvas);
};

/**
 * Set a function that will be called whenever the parent map and the overlay's
 * canvas have been resized. If opt_resizeHandler is null or unspecified, any
 * existing callback is removed.
 * @param {?function=} opt_resizeHandler The resize callback function.
 */
CanvasLayer.prototype.setResizeHandler = function (opt_resizeHandler) {
    this.resizeHandler_ = opt_resizeHandler;
};

/**
 * Sets a value for scaling the canvas resolution relative to the canvas
 * display size. This can be used to save computation by scaling the backing
 * buffer down, or to support high DPI devices by scaling it up (by e.g.
 * window.devicePixelRatio).
 * @param {number} scale
 */
CanvasLayer.prototype.setResolutionScale = function (scale) {
    if (typeof scale === 'number') {
        this.resolutionScale_ = scale;
        this.resize_();
    }
};

/**
 * Set a function that will be called when a repaint of the canvas is required.
 * If opt_updateHandler is null or unspecified, any existing callback is
 * removed.
 * @param {?function=} opt_updateHandler The update callback function.
 */
CanvasLayer.prototype.setUpdateHandler = function (opt_updateHandler) {
    this.updateHandler_ = opt_updateHandler;
};

/**
 * @inheritDoc
 */
CanvasLayer.prototype.onAdd = function () {
    if (this.isAdded_) {
        return;
    }

    this.isAdded_ = true;
    this.setPane_();

    this.resizeListener_ = google.maps.event.addListener(this.getMap(),
        'resize', this.resizeFunction_);
    this.centerListener_ = google.maps.event.addListener(this.getMap(),
        'center_changed', this.repositionFunction_);

    this.resize_();
    this.repositionCanvas_();
};

/**
 * @inheritDoc
 */
CanvasLayer.prototype.onRemove = function () {
    if (!this.isAdded_) {
        return;
    }

    this.isAdded_ = false;
    this.topLeft_ = null;

    // remove canvas and listeners for pan and resize from map
    this.canvas.parentElement.removeChild(this.canvas);
    if (this.centerListener_) {
        google.maps.event.removeListener(this.centerListener_);
        this.centerListener_ = null;
    }
    if (this.resizeListener_) {
        google.maps.event.removeListener(this.resizeListener_);
        this.resizeListener_ = null;
    }

    // cease canvas update callbacks
    if (this.requestAnimationFrameId_) {
        this.cancelAnimFrame_.call(window, this.requestAnimationFrameId_);
        this.requestAnimationFrameId_ = null;
    }
};

/**
 * The internal callback for resize events that resizes the canvas to keep the
 * map properly covered.
 * @private
 */
CanvasLayer.prototype.resize_ = function () {
    if (!this.isAdded_) {
        return;
    }

    const map = this.getMap();
    const mapWidth = map.getDiv().offsetWidth;
    const mapHeight = map.getDiv().offsetHeight;

    const newWidth = mapWidth * this.resolutionScale_;
    const newHeight = mapHeight * this.resolutionScale_;
    const oldWidth = this.canvas.width;
    const oldHeight = this.canvas.height;

    // resizing may allocate a new back buffer, so do so conservatively
    if (oldWidth !== newWidth || oldHeight !== newHeight) {
        this.canvas.width = newWidth;
        this.canvas.height = newHeight;

        this.needsResize_ = true;
        this.scheduleUpdate();
    }

    // reset styling if new sizes don't match; resize of data not needed
    if (this.canvasCssWidth_ !== mapWidth ||
        this.canvasCssHeight_ !== mapHeight) {
        this.canvasCssWidth_ = mapWidth;
        this.canvasCssHeight_ = mapHeight;
        this.canvas.style.width = mapWidth + 'px';
        this.canvas.style.height = mapHeight + 'px';
    }
};

/**
 * @inheritDoc
 */
CanvasLayer.prototype.draw = function () {
    this.repositionCanvas_();
};

/**
 * Internal callback for map view changes. Since the Maps API moves the overlay
 * along with the map, this function calculates the opposite translation to
 * keep the canvas in place.
 * @private
 */
CanvasLayer.prototype.repositionCanvas_ = function () {
    // TODO(bckenny): *should* only be executed on RAF, but in current browsers
    //     this causes noticeable hitches in map and overlay relative
    //     positioning.

    const map = this.getMap();

    // topLeft can't be calculated from map.getBounds(), because bounds are
    // clamped to -180 and 180 when completely zoomed out. Instead, calculate
    // left as an offset from the center, which is an unwrapped LatLng.
    const top = map.getBounds().getNorthEast().lat();
    const center = map.getCenter();
    const scale = Math.pow(2, map.getZoom());
    const left = center.lng() - (this.canvasCssWidth_ * 180) / (256 * scale);
    this.topLeft_ = new google.maps.LatLng(top, left);

    // Canvas position relative to draggable map's container depends on
    // overlayView's projection, not the map's. Have to use the center of the
    // map for this, not the top left, for the same reason as above.
    const projection = this.getProjection();
    const divCenter = projection.fromLatLngToDivPixel(center);
    const offsetX = -Math.round(this.canvasCssWidth_ / 2 - divCenter.x);
    const offsetY = -Math.round(this.canvasCssHeight_ / 2 - divCenter.y);
    this.canvas.style[CanvasLayer.CSS_TRANSFORM_] = 'translate(' +
        offsetX + 'px,' + offsetY + 'px)';

    this.scheduleUpdate();
};

/**
 * Internal callback that serves as main animation scheduler via
 * requestAnimationFrame. Calls resize and update callbacks if set, and
 * schedules the next frame if overlay is animated.
 * @private
 */
CanvasLayer.prototype.update_ = function () {
    this.requestAnimationFrameId_ = null;

    if (!this.isAdded_) {
        return;
    }

    if (this.isAnimated_) {
        this.scheduleUpdate();
    }

    if (this.needsResize_ && this.resizeHandler_) {
        this.needsResize_ = false;
        this.resizeHandler_();
    }

    if (this.updateHandler_) {
        this.updateHandler_();
    }
};

/**
 * A convenience method to get the current LatLng coordinate of the top left of
 * the current view of the map.
 * @return {google.maps.LatLng} The top left coordinate.
 */
CanvasLayer.prototype.getTopLeft = function () {
    return this.topLeft_;
};

/**
 * Schedule a requestAnimationFrame callback to updateHandler. If one is
 * already scheduled, there is no effect.
 */
CanvasLayer.prototype.scheduleUpdate = function () {
    if (this.isAdded_ && !this.requestAnimationFrameId_) {
        this.requestAnimationFrameId_ =
            this.requestAnimFrame_.call(window, this.requestUpdateFunction_);
    }
};
