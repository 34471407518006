import React, { Component, Fragment, PropTypes } from 'react';
import { connect, match } from 'react-redux';
import { getAccess } from 'containers/Landing/landingActions';

import { Link } from 'react-router-dom';

import { IconLogo } from "../../core/icons";

class Landing extends Component {

    constructor(state) {
        super(state);
        this.state = {
            //modal
            loginActive: false,
            ndaActive: false,
            pageToRedirect: '',
        };
        this.showModalLogin = this.showModalLogin.bind(this);
        this.hideModalLogin = this.hideModalLogin.bind(this);
        this.showNdaModal = this.showNdaModal.bind(this);
    }

    componentDidMount() {
        document.title = 'Frosty Acres Brands';
        window.scrollTo(0, 0);
        this.props.getAccess();
    }
    showModalLogin(page) {
        this.setState({
            loginActive: true,
            pageToRedirect: page
        });
    }
    hideModalLogin(redirect) {
        this.setState({
            loginActive: false,
        });
        if(redirect === true) {
            window.location.href = `${process.env.PUBLIC_URL}${this.state.pageToRedirect}`;
        }
    }
    showNdaModal(param) {
        this.setState({
            ndaActive: param,
        });
    }

    render() {
        const accessNafem = this.props.access.accessNafem;
        const accessIsFirst = this.props.access.accessIsFirst;
        //const accessIsFirst = true;
        return (
        <Fragment>
            <div className={"landing" + ((accessIsFirst !== undefined && accessIsFirst === true) ? ' is-animate' : '')}>
                <IconLogo className="landing__logo-new"/>

                <div className="landing__intro-block">
                    <div className="landing-content">
                        {accessNafem &&
                            <Link
                                to="/markets/0-total_us"
                                className="landing-content__col landing-content__button animation-button-1"
                            >
                                <div className="landing-content__button-inner animation-inner-1 is-landscape">
                                    <div className="landing-content__button-title">Firefly<br/>Landscape</div>
                                </div>
                                <div className="landing-content__description is-landscape">
                                    <div className="landing-content__description-inner">
                                        Design your segment-specific plans with all the data you need, including DMA-level profiles.
                                    </div>
                                </div>
                            </Link>
                        }
                    </div>

                </div>
            </div>
        </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    access: state.landingpageReducer.access,
});

const mapDispatchToProps = dispatch => ({
    getAccess: () => dispatch(getAccess())
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);