import { ReactComponent as Icon1 } from 'assets/icons/advanced-search.svg';
import { ReactComponent as Icon2 } from 'assets/icons/albums.svg';
import { ReactComponent as Icon3 } from 'assets/icons/arr-light.svg';
import { ReactComponent as Icon4 } from 'assets/icons/arrow-next.svg';
import { ReactComponent as Icon5 } from 'assets/icons/arrow-target.svg';
import { ReactComponent as Icon6 } from 'assets/icons/arrows-in-circle.svg';
import { ReactComponent as Icon7 } from 'assets/icons/checkbox.svg';
import { ReactComponent as Icon8 } from 'assets/icons/checkbox2.svg';
import { ReactComponent as Icon9 } from 'assets/icons/dots.svg';
import { ReactComponent as Icon10 } from 'assets/icons/earth-globe.svg';
import { ReactComponent as Icon11 } from 'assets/icons/email.svg';
import { ReactComponent as Icon12 } from 'assets/icons/export.svg';
import { ReactComponent as Icon13 } from 'assets/icons/Firefly.svg';
import { ReactComponent as Icon14 } from 'assets/icons/footer-bg.svg';
import { ReactComponent as Icon15 } from 'assets/icons/ifma-logo-new.svg';
import { ReactComponent as Icon16 } from 'assets/icons/ifma-portal.svg';
import { ReactComponent as Icon17 } from 'assets/icons/ifma-scope.svg';
import { ReactComponent as Icon18 } from 'assets/icons/IFMA_logo.svg';
import { ReactComponent as Icon19 } from 'assets/icons/IFMA_red_colored_white.svg';
import { ReactComponent as Icon20 } from 'assets/icons/IFMA_Scope_1CWhite.svg';
import { ReactComponent as Icon21 } from 'assets/icons/IFMA_Scope_Color.svg';
import { ReactComponent as Icon22 } from 'assets/icons/intro-left.svg';
import { ReactComponent as Icon23 } from 'assets/icons/intro-right-text.svg';
import { ReactComponent as Icon24 } from 'assets/icons/kinetik12_logo.svg';
import { ReactComponent as Icon25 } from 'assets/icons/lines.svg';
import { ReactComponent as Icon26 } from 'assets/icons/lock4.svg';
import { ReactComponent as Icon27 } from 'assets/icons/logo-ifma-scope-white-intro.svg';
import { ReactComponent as Icon28 } from 'assets/icons/logo-white.svg';
import { ReactComponent as Icon29 } from 'assets/icons/Logo_Datassential_2.svg';
import { ReactComponent as Icon30 } from 'assets/icons/logo_ds.svg';
import { ReactComponent as Icon31 } from 'assets/icons/logo_ds2.svg';
import { ReactComponent as Icon32 } from 'assets/icons/logout.svg';
import { ReactComponent as Icon35 } from 'assets/icons/nav_chains.svg';
import { ReactComponent as Icon36 } from 'assets/icons/nav_gtm.svg';
import { ReactComponent as Icon37 } from 'assets/icons/nav_segments.svg';
import { ReactComponent as Icon38 } from 'assets/icons/oval.svg';
import { ReactComponent as Icon39 } from 'assets/icons/paper.svg';
import { ReactComponent as Icon40 } from 'assets/icons/plus.svg';
import { ReactComponent as Icon41 } from 'assets/icons/ppt_export.svg';
import { ReactComponent as Icon42 } from 'assets/icons/remove.svg';
import { ReactComponent as Icon43 } from 'assets/icons/search.svg';
import { ReactComponent as Icon44 } from 'assets/icons/search2.svg';
import { ReactComponent as Icon45 } from 'assets/icons/search-grey.svg';
import { ReactComponent as Icon46 } from 'assets/icons/search_doc_icon.svg';
import { ReactComponent as Icon47 } from 'assets/icons/SNAP.svg';
import { ReactComponent as Icon48 } from 'assets/icons/sort.svg';
import { ReactComponent as Icon49 } from 'assets/icons/sort-asc.svg';
import { ReactComponent as Icon50 } from 'assets/icons/sort-desc.svg';
import { ReactComponent as Icon51 } from 'assets/icons/spinner.svg';
import { ReactComponent as Icon52 } from 'assets/icons/spinner-2.svg';
import { ReactComponent as Icon53 } from 'assets/icons/twelve.svg';
import { ReactComponent as Icon54 } from 'assets/icons/unlock-alt.svg';
import { ReactComponent as Icon55 } from 'assets/icons/us_map.svg';
import { ReactComponent as Icon56 } from 'assets/icons/user.svg';
import { ReactComponent as Icon57 } from 'assets/icons/xls.svg';
import { ReactComponent as SvgLogo } from 'assets/icons/Logo.svg';
import { ReactComponent as SvgDsLogoWhite } from 'assets/icons/DS-Logo-white.svg';

export const IconLogo = SvgLogo;
export const IconDsLogoWhite = SvgDsLogoWhite;

export const Icons = {
    'advanced-search': Icon1,
    'albums': Icon2,
    'arr-light': Icon3,
    'arrow-next': Icon4,
    'arrow-target': Icon5,
    'arrows-in-circle': Icon6,
    'checkbox': Icon7,
    'checkbox2': Icon8,
    'dots': Icon9,
    'earth-globe': Icon10,
    'email': Icon11,
    'export': Icon12,
    'Firefly': Icon13,
    'footer-bg': Icon14,
    'ifma-logo-new': Icon15,
    'ifma-portal': Icon16,
    'ifma-scope': Icon17,
    'IFMA_logo': Icon18,
    'IFMA_red_colored_white': Icon19,
    'IFMA_Scope_1CWhite': Icon20,
    'IFMA_Scope_Color': Icon21,
    'intro-left': Icon22,
    'intro-right-text': Icon23,
    'kinetik12_logo': Icon24,
    'lines': Icon25,
    'lock4': Icon26,
    'logo-ifma-scope-white-intro': Icon27,
    'logo-white': Icon28,
    'Logo_Datassential_2': Icon29,
    'logo_ds': Icon30,
    'logo_ds2': Icon31,
    'logout': Icon32,
    'nav_chains': Icon35,
    'nav_gtm': Icon36,
    'nav_segments': Icon37,
    'oval': Icon38,
    'paper': Icon39,
    'plus': Icon40,
    'ppt_export': Icon41,
    'remove': Icon42,
    'search': Icon43,
    'search2': Icon44,
    'search-grey': Icon45,
    'search_doc_icon': Icon46,
    'SNAP': Icon47,
    'sort': Icon48,
    'sort-asc': Icon49,
    'sort-desc': Icon50,
    'spinner': Icon51,
    'spinner-2': Icon52,
    'twelve': Icon53,
    'unlock-alt': Icon54,
    'us_map': Icon55,
    'user': Icon56,
    'xls': Icon57,
    'Logo': SvgLogo,
}

export function getIcon (name) {
    return Icons[name] || function () {return `no icon "${name}" found`};
}