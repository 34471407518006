import React, { Component, Fragment } from 'react';
import './style.scss';

import { connect } from 'react-redux';

import { getAccess } from 'containers/Landing/landingActions';

import Icon from 'components/main/svg-component';
import MainSearch from 'components/main/main-search';
import ModalEmailSuccess from 'components/main/modal-email-success';
import Navigation from 'components/main/navigation';
import { __fetch } from "App";
import { API_ACCESS, API_URL, LOGOUT_URL } from "../../../core/constants";

class Header extends Component {
    constructor(state){
        super(state);
        this.state = {
            loginActive: false,
            ndaActive: false,

            userInfoVisible: false,
            menuVisible: false,

            contactSuccess: false,
            modalSuccessVisible: false
        };

        this.showUserInfo = this.showUserInfo.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.onMouseEnterHandlerBody = this.onMouseEnterHandlerBody.bind(this);
        this.onMouseLeaveHandlerBody = this.onMouseLeaveHandlerBody.bind(this);
        this.getCredentials = this.getCredentials.bind(this);

        this.emailToAssistant = this.emailToAssistant.bind(this);

        this.showModalEmailToAssistant = this.showModalEmailToAssistant.bind(this);
        this.hideModalEmailToAssistant = this.hideModalEmailToAssistant.bind(this);
    }
    componentDidMount() {
        this.getCredentials();
    }
    getCredentials() {
        const _this = this;
        const credentialsUrl = `${API_ACCESS}/get-credentials`;
       __fetch(credentialsUrl)
            .then(res => res.json())
            .then(response => {
                const acceptNda = response['accept-nda'];
                if(response.ifma) {
                    if(!acceptNda) {
                        _this.setState({
                            loginActive: true,
                            ndaActive: true,
                        });
                    }
                }
            });
    }
    onMouseEnterHandlerBody() {
        this.setState({
            userInfoVisible: true,
        });
    }
    onMouseLeaveHandlerBody() {
        this.setState({
            userInfoVisible: false,
        });
    }
    showModalEmailToAssistant() {
        this.setState({
            modalSuccessVisible: true,
        });
    }
    hideModalEmailToAssistant() {
        this.setState({
            modalSuccessVisible: false,
        });
    }
    showUserInfo() {
        document.addEventListener('click', this.handleOutsideClick, false);
        const prevState = this.state.userInfoVisible;
        this.setState({
            userInfoVisible: !prevState,
        });
    }
    handleClick() {
        if (!this.state.userInfoVisible) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
            this.setState({
                userInfoVisible: false,
            });
        }
    }
    handleOutsideClick(e) {
        if (this.node.contains(e.target)) {
            return;
        }
        this.handleClick();
    }
    emailToAssistant() {
        const _this = this;
        const credentialsUrl = `${API_URL}/landing/default/assistance-email-send`;
       __fetch(credentialsUrl)
            .then(res => res.json())
            .then(response => {
                this.setState({
                    modalSuccessVisible: true,
                    //contactSuccess: true
                });
            });
    }
    render() {
        const {path, pageTitle} = this.props;
        const pathGTM = /^\/gtm.*$/gi.test(path);
        return (
            <Fragment>
                <div className={"main-header"}>

                    <div className="main-header__content">

                        <div className="main-header__el-small main-header__el-tall">
                            <Navigation
                                path={path}
                                navAnimation={this.props.navAnimation}
                                accessNafem={this.props.accessNafem}
                                accessSnap={this.props.accessSnap}
                            />
                        </div>

                        <div className="main-header__el-full"></div>

                        <div className={"main-header__el-title" + (!this.props.access.accessSnap ? ' is-absolute' : '')}>
                            <div className="main-header__title">{pageTitle}</div>
                        </div>

                        <div className="main-header__el-small">
                            <MainSearch access={this.props.access} />
                        </div>

                        <div
                            className="main-header__el-small"
                        >
                            <div
                                className="user-holder"
                                onMouseEnter={this.onMouseEnterHandlerBody.bind(this)}
                                onMouseLeave={this.onMouseLeaveHandlerBody.bind(this)}
                            >
                                <div
                                    className="user"
                                >
                                    <div className="user__icon"><Icon iconClassName='user' /></div>
                                </div>
                                <div className={"user__info" + (this.state.userInfoVisible ? ' is-visible' : '')}>
                                    <div className="user__info-inner">
                                        <div className={"user__name" + (!this.props.access.accessNafem ? ' is-action' : '')}>
                                            {this.props.access.accessEmail.value}
                                        </div>

                                        <div
                                            className={"user__buttons-set is-center"}
                                            style={{display: 'none'}}
                                        >
                                            {this.props.access.accessNafem &&
                                            <a href={`${LOGOUT_URL}/default/logout`} className="user__button">Log out</a>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <ModalEmailSuccess
                    modalVisible={this.state.modalSuccessVisible}
                    showNdaModal={this.showModalEmailToAssistant}
                    hideModalLogin={this.hideModalEmailToAssistant.bind(this)}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => ({
    access: state.landingpageReducer.access,
});

const mapDispatchToProps = dispatch => ({
    getAccess: () => dispatch(getAccess()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);