import React, { Component } from 'react';
import './style.scss';
import LineChart from 'components/main/line-chart';


export default class Generations extends Component {

    render() {
        const {metroId, marketConsumer} = this.props;
        const chartData = marketConsumer && marketConsumer.generations;
        const medianAgeData = marketConsumer && marketConsumer.median_age;
        return (
            <div className="ts-block__content-modul">
                <div className="ts-mod-header">Generations</div>

                <div className="generations">
                    <div className="generations__col-data">
                        <div className="sticker">
                            <div className="sticker__count">{marketConsumer && medianAgeData.value}</div>
                            <div className="sticker__name">Median age</div>
                            {metroId !== '0' &&
                            <div>
                                <div className="sticker__caption">national avg.</div>
                                <div className="sticker__caption-count">{marketConsumer && medianAgeData.total_value}</div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="generations__col-chart">
                        <LineChart
                            metroId={metroId}
                            chartData={chartData}
                            total={true}
                        />
                    </div>
                </div>

            </div>
        )
    }
}