import { combineReducers } from 'redux';
import { landingReducers } from 'containers/Landing';
import { marketsReducers } from 'containers/Markets';
import { segmentsReducers } from 'containers/Segments';
import {
    metroHeaderReducer,
    metroOverviewReducer,
    metroConsumerReducer,
    metroOperatorReducer
} from 'containers/Metro';
//
export default combineReducers({
    landingpageReducer: landingReducers,
    metropageReducer: metroHeaderReducer,
    metroOverviewReducer: metroOverviewReducer,
    marketspageReducer: marketsReducers,
    metroConsumerReducer: metroConsumerReducer,
    metroOperatorReducer: metroOperatorReducer,
    segmentspageReducer: segmentsReducers,
});
