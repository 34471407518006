import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';
import GA4React from "ga-4-react";
import 'assets/styles/general.scss';
import Footer from 'components/main/footer';
import { LandingComponent } from 'containers/Landing';
import { MarketsComponent } from 'containers/Markets';
import { MetroComponent } from 'containers/Metro';
import { SegmentsComponent } from 'containers/Segments';
import { ItemSegmentComponent } from 'containers/ItemSegment';

import {NotFound} from 'containers/NotFound';
import {Error500} from 'containers/Error500';

const gaID = `G-${process.env.REACT_APP_GA_ID}`;
const ga4react = new GA4React(gaID);

class MainContainer extends Component {
    constructor(state) {
        super(state);
        this.state = {
            view: 'home',
            id: 1,
            mainOverlay: false,
            mainOverlayDisabled: true,
            introDisabled: false,
            accessNafem: null
        }
    }
    componentDidMount() {
        const ga4react = new GA4React(gaID);
        ga4react.initialize().then((ga4) => {
            ga4.pageview(window.location.pathname + window.location.search)
            //ga4.pageview('path')
            ga4.gtag('event','pageview','path') // or your custom gtag event
        },(err) => {
            console.error(err)
        });

        const _this = this;

        setTimeout(function() {
            _this.setState({
                introDisabled: true,
            });
        }, 6000);
        setTimeout(() => {
            _this.setState({
                mainOverlayDisabled: false,
            });
        }, 5900);
        setTimeout(() => {
            _this.setState({
                mainOverlayDisabled: true,
            });
        }, 7300);
    }
    componentDidUpdate() {}
    getCredentials(accessCredentials) {
        const responseIfma = accessCredentials['ifma'] ? 'logging in' : 'logging off';
        //ReactGA.set({ dimension1: responseIfma });
    }

    render() {
        const accessCredentials = this.props.access.accessCredentials;
        const accessCredentialsBase = this.props.access.accessCredentials['base'];
        if(accessCredentialsBase) {
            this.getCredentials(accessCredentials);
        }
        return (
            <Router basename={process.env.PUBLIC_URL}>
                <Fragment>
                    <Switch>
                        <Route exact path="/" component={ LandingComponent }/>
                        <Route exact path="/markets" component={MarketsComponent}/>
                        <Route exact path="/markets/:id-:type" component={MetroComponent}/>
                        <Route exact path="/segments" component={SegmentsComponent}/>
                        <Route exact path="/segments/:id" component={ItemSegmentComponent}/>

                        <Route path="/500" component={Error500}/>
                        <Route path="*" component={NotFound}/>

                    </Switch>

                    <Footer />
                </Fragment>
            </Router>

        )
    }
}

const mapStateToProps = state => ({
    accessIsFirst: state.landingpageReducer.accessIsFirst,
    access: state.landingpageReducer.access
});

const mapDispatchToProps = dispatch => ({
    //getMapBorders: (propId) => dispatch(getMapBorders(propId)),
});
export default connect(mapStateToProps)(MainContainer);