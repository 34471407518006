import {
    MARKETS_GET_DATA
} from './marketsActionTypes';

const marketsState = {
    metroAreas: [],
    censusRegions: []
};

export default function marketsReducer(state = marketsState, action) {
    switch (action.type) {
        case MARKETS_GET_DATA: {
            return {
                ...state,
                metroAreas: action.data,
            }
        }
        default: {
            return state;
        }
    }
}
