import React from 'react';
import { getIcon } from "../../../core/icons";

function SpriteIcon(
    {
        iconId,
        width = 16,
        height = 16,
        className = 'icon',
        style,
    }
) {
    const IconComponent = getIcon(iconId);
    return (
        <IconComponent
            width={width}
            height={height}
            className={className}
            style={style}
        />
    );
}

export default SpriteIcon;