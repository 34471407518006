import React from 'react';
import ReactDOM from 'react-dom';

import MainContainer from './containers/MainContainer';

import { store } from './store/store';
import { Provider } from 'react-redux';

export default function App () {
    return (
        <Provider
            store={store}
        >
            <MainContainer/>
        </Provider>
    );
}

export function __fetch(url, data = {}) {
    return fetch(`${url}${url.indexOf('?') > -1 ? '&' : '?'}t=${(new Date()).getTime()}`, {
        ...data,
        credentials: 'include',
    });
}