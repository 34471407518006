import React, { Component, Fragment } from 'react';
import './style.scss';

import Icon from 'components/main/svg-component';
import FilterTable from 'components/main/filter-table';

export default class Table extends Component {
    constructor(state) {
        super(state);
        this.state = {
            hover: false,

        };
        this.changeRadio = this.changeRadio.bind(this);
        this.clickLinkAccess = this.clickLinkAccess.bind(this);
        this.filterSend = this.filterSend.bind(this);
        this.filterCuisinesSend = this.filterCuisinesSend.bind(this);
    }

    clickAction(key) {
        this.props.sortingTableAction(key);
    }

    changeRadio(id) {
        this.props.checkAction(id)
    }

    clickLinkAccess(type, target, name) {
        this.props.clickLink(type, target, name)
    }

    filterSend(filterType, event) {
        const val = event.target === undefined ? event : event.target.value;
        this.props.filterAction(filterType, val)
    }
    tableActionSearch(filterType, event) {
        const val = event.target === undefined ? event : event.target.value;
        this.props.tableSearch(filterType, val)
    }
    filterCuisinesSend(filterType, event) {
        const val = event.target === undefined ? event : event.target.value;
        this.props.filterCuisinesAction(filterType, val)
    }
    autosuggestListSend(filterType, event) {
        this.props.getAutosuggestList(filterType, event.target.value)
    }

    render() {
        const {
            tableData,
            customHeaderData,
            tableClass = '',
            firstColClass = '',
            colClass = '',
            tableSubHeader= '',
            colCount,
            tableSubHeaderClass = '',
            cellClass = '',
            trafficSummaryActiveId,
            tableActive,
            rowAction,
            hover,
            tableSort,
            tableSortType,
            segmentsAction,
            inputName,
            marketStructureActive,
            filterSearch,
            filterAutoSuggest,
            filterList,
            autosuggestList,
            cuisine,
            segment,
            filterValue,
            autoSuggestValue,
            tableSearchValue,
            metroId,
            segmentId,
            cuisineId
        } = this.props;
        const tableSubHeaderCaption = this.props.tableSubHeaderCaption ? <span className="go-table__subtitle-small">{this.props.tableSubHeaderCaption}</span> : null;
        const _this = this;
        if (!tableData) {
            return null;
        }

        const tableSubHeaderData = (
            <tr>
                <td className={'go-table__section-title ' + tableSubHeaderClass} colSpan={colCount}>{tableSubHeader} {tableSubHeaderCaption}</td>
            </tr>
        );

        const tableDataCopy = tableData.body ? [...tableData.body] : null;
        const tableHeaderCopy = tableData.header ? [{ ...tableData.header }] : null;
        const tableFooterCopy = tableData.total ? [{ ...tableData.total }] : null;
        const tableCustomHeaderCopy = customHeaderData ? [{ ...customHeaderData }] : null;
        const tableSectionTitle = tableSubHeader ? tableSubHeaderData : null;
        const tableHead = (
            <thead>
            {
                tableHeaderCopy && tableHeaderCopy.map(row => (
                    Object.values(row).map((cell, rowIndex) => (
                        <tr key={rowIndex}>
                            {Object.values(cell).map((col, cellIndex) => {
                                return <td
                                    key={cellIndex} className={"go-table__th " + (cellIndex !== 0 ? colClass : '') + ' '  + (cellIndex === 0 ? firstColClass : '')}
                                >
                                    <div className={'go-table__th-wrapper' + (col['sort'] ? ' go-table__th-wrapper--with-sort' : '')}>
                                        {col['sort'] ?  <div className="table-sort" onClick={this.clickAction.bind(this, col['sort_key'])}>
                                                <span className="ts-icon-font">
                                                    {col['sort_key'] === tableSort ? (
                                                            tableSortType === 'desc' ? (<Icon iconClassName='sort-desc' />) : (<Icon iconClassName='sort-asc' />)
                                                        ) : (<Icon iconClassName='sort' />)}
                                                </span>
                                                <div className="go-table__th-title"
                                                        dangerouslySetInnerHTML={{ __html: col['value'] }}>
                                                </div>
                                            </div> :
                                            <div className="go-table__th-title"
                                                 dangerouslySetInnerHTML={{ __html: col['value'] }}>
                                            </div>
                                        }

                                        {col['filter'] ?
                                            <FilterTable
                                                filterSearch={filterSearch}
                                                filterAutoSuggest={filterAutoSuggest}
                                                filterList={filterList}
                                                sortKey={col['sort_key']}
                                                filterSend={this.filterSend}
                                                filterCuisinesSend={this.filterCuisinesSend}
                                                tableActionSearch={this.tableActionSearch.bind(this)}
                                                autosuggestListSend={this.autosuggestListSend.bind(this)}
                                                autosuggestList={autosuggestList}
                                                list_cuisine={cuisine}
                                                list_segment={segment}
                                                filterValue={filterValue}
                                                autoSuggestValue={autoSuggestValue}
                                                tableSearchValue={tableSearchValue}
                                                metroId={metroId}
                                                segmentId={segmentId}
                                                cuisineId={cuisineId}
                                            />

                                            : null}
                                    </div>

                                </td>
                            })}
                        </tr>
                    ))
                ))
            }
            {
                tableCustomHeaderCopy && tableCustomHeaderCopy.map(row => (
                    Object.values(row).map((cell, rowIndex) => (
                        <tr key={rowIndex}>
                            {Object.values(cell).map((col, cellIndex) => (
                                <td key={cellIndex} className={"go-table__th " + (cellIndex !== 0 ? colClass : '')}>
                                    <div className="go-table__th-wrapper">
                                        {hover ? (
                                                col['value'] ? (
                                                        <div className="tooltip is-center">
                                                            This is a blank description and we will have a real one soon
                                                        </div>
                                                    ) : null
                                            ) : null
                                        }
                                        <span className="go-table__th-title"
                                              dangerouslySetInnerHTML={{ __html: col['value'] }}/>
                                    </div>
                                </td>))}
                        </tr>
                    ))
                ))
            }
            </thead>
        );
        const tableBody = tableDataCopy !== null && tableDataCopy.length === 0 ? <tr><td className="go-table__td is-centered" colSpan={tableHeaderCopy[0].cells.length}>No results</td></tr> :
            tableDataCopy && tableDataCopy.map(function (row, index) {
                return Object.values(row).map(function (cell, k) {
                    return <tr
                        key={k}
                        className={tableActive ? (index === trafficSummaryActiveId ? 'go-table__row-action is-selected' : 'go-table__row-action') : null}
                        onClick={tableActive ? rowAction.bind(this, index, cell[0].value) : null}
                        id={k}
                    >
                        {Object.values(cell).map(function (col, c) {
                            const colVal = col['value'];
                            const inputId = inputName + '_' + col['key'];
                            const tdClass = "go-table__td " + cellClass + ' ' + (c === 0 ? firstColClass : '');
                            const inputRadio = <div className="go-table__input-row">
                                <span className="ts-radio">
                                    <input
                                        className="go-table__input-radio ts-radio__input" type="radio"
                                        defaultChecked={index === 0 ? true : false}
                                        id={inputId}
                                        name={inputName}
                                        onChange={_this.changeRadio.bind(this, col['key'])}
                                    />
                                    <label className="ts-radio__label"><span className="ts-radio__icon"></span></label>
                                </span>
                                    <label htmlFor={inputId} className="go-table__input-label">{col['value']}</label>
                                </div>;
                            const markInput = <div className="go-table__input-row">
                                <span className={"go-table__input-mark" + (marketStructureActive == col['parent_key'] ? ' is-active' : '') + (' market_' + colVal.replace(/[\W_]/g, '').toLowerCase())}></span>
                                <span>{col['value']}</span>
                            </div>;
                                if(segmentsAction) {
                                    if(col['is_parent'] && c === 0) {
                                        return <td key={c}
                                                   className={tdClass}>
                                            {
                                                inputRadio
                                            }
                                        </td>
                                    } else if(col['is_parent'] === false && c === 0) {
                                        return <td key={c} className={tdClass}>{markInput}</td>
                                    } else {
                                        return <td key={c} className={tdClass}>{col['value']}</td>
                                    }
                                } else if(col['url'] !== undefined) {
                                    return <td key={c} className={tdClass}>
                                        <a
                                            href={col.url}
                                            target="_blank"
                                            className='table__link'>{col['value']}
                                        </a>
                                    </td>
                                } else if(col['popup'] !== undefined) {
                                    return <td key={c} className={tdClass}>
                                        <span
                                            className='table__link'
                                            onClick={_this.clickLinkAccess.bind(this, col.popup.type, col.popup.target, col['value'])}
                                        >{col['value']}</span>
                                    </td>
                                } else {
                                    return <td key={c} className={tdClass}>{col['value']}</td>
                                }
                        })}
                    </tr>
                })
            })
        ;

        const tableFooter = (
            <tfoot>{tableFooterCopy && tableFooterCopy.map(function (row, index) {
                return Object.values(row).map(function (cell, k) {
                    return <tr key={k}>
                        {Object.values(cell).map(function (col, c) {
                            return <td key={c} className="go-table__tf">{col['value']}</td>
                        })}
                    </tr>
                })
            })}</tfoot>
        );

        return (
            <Fragment>
                <table className={"go-table " + tableClass}>
                    {tableHead}
                    <tbody>
                    {tableSectionTitle}
                    {tableBody}
                    </tbody>
                    {tableFooter}
                </table>
            </Fragment>
        )
    }
}