import React, { Component } from 'react';
import './style.scss';


export default class Density extends Component {

    render() {
        const {metroId, marketConsumer} = this.props;
        const densityData = marketConsumer && marketConsumer.density;
        return (
            <div className="ts-block__content-modul border-bottom clearfix">
                <div className="ts-mod-header">TOTAL RESTAURANTS PER<br/>1,000 CONSUMERS IN THAT AREA</div>
                <div className={"density" + (metroId === '0' ? ' is-total' : '')}>
                    {metroId !== '0' &&
                    <div className="density__col">
                        <div className="sticker">
                            <div className="sticker__density-count">{densityData && densityData.current}</div>
                            <div className="sticker__name">CURRENT AREA</div>
                        </div>
                    </div>
                    }
                    <div className="density__col">
                        <div className="sticker">
                            <div className="sticker__density-count is-total">{densityData && densityData.total}</div>
                            <div className="sticker__name">TOTAL US</div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}