import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import bgPage from 'assets/images/bg-page.png';

export default class Page500 extends Component {

    render() {
        return (
            <div
                className="error-content"
                //style={{backgroundImage: "url(" + bgPage + ")"}}
            >
                <div className="error-content__inner">
                    <div className="error-content__title">Error</div>
                    <div className="error-content__error-name">500</div>
                    {/*<div className="error-content__caption">You’re looking for something that doesn’t actually exist...</div>*/}
                    <Link to="/" className="button blue">Homepage</Link>
                </div>
            </div>
        )
    }
}