import serializeParams from './serializeParams';
import { LOGIN_URL } from "../core/constants";

const defaultFetchOptions = {
    method: 'get',
    credentials: 'include',
    headers: {
        'Content-type': 'application/json',
    }
};

class Http {
    constructor() {
        this.getOptions = { ...defaultFetchOptions, method: 'get' };
        this.postOptions = { ...defaultFetchOptions, method: 'post' };
    }

    getQuery(type, params = {}) {
        // let query = this.getUrlFromType(type);
        let query = type;
        if (Object.keys(params).length) {
            query += `?${serializeParams(params)}`;
        }
        return query;
    }

    // TODO replace with one config object
    fetch(type, params, options, success, responseType) {
        const query = this.getQuery(type, params);
        fetch(`${query}${query.indexOf('?') > -1 ? '&' : '?'}t=${(new Date()).getTime()}`, options)
            .then((response) => {
                if (response.status === 401) {
                    window.location.href = LOGIN_URL;
                }
                if (response.status === 400) {
                    window.location.href = `${process.env.PUBLIC_URL}404`;
                }
                if (response.status === 200) {
                    switch (responseType) {
                        case 'file': {
                            response.blob()
                                .then((result) => {
                                    success.resolve(result);
                                })
                                .catch(error => console.error(query, error));
                            break;
                        }
                        case 'bool': {
                            response.text()
                                .then((result) => {
                                    success.resolve(result);
                                })
                                .catch(error => console.error('fetch', query, error));
                            break;
                        }
                        default: {
                            response.json()
                                .then((result) => {
                                    success.resolve(result);
                                })
                                .catch(error => {
                                    console.error('fetch', query, error);
                                    success.reject(error);
                                });
                        }
                    }
                }
            })
            .catch(error => {
                //console.log(error);
                // window.location.href = '/404';
            });
    }

    get(type, params = {}, getOptions = {}, responseType) {
        return new Promise((resolve, reject) => this.fetch(
            type,
            params,
            {
                ...this.getOptions,
                ...getOptions,
                // headers: {
                //     Authorization: `Bearer ${getCredentials().token}`
                // }
            },
            { resolve, reject },
            responseType
        ));
    }

    post(type, params = {}, postOptions = {}, responseType) {
        return new Promise((resolve, reject) => this.fetch(
            type,
            params,
            {
                ...this.postOptions,
                ...postOptions,
                headers: {
                    ...this.postOptions.headers,
                    ...postOptions.headers,
                    // Authorization: `Bearer ${getCredentials().token}`
                }
            },
            { resolve, reject },
            responseType
        ));
    }

}

export default new Http();
