import React, { Component, Fragment } from 'react';
import './style.scss';

export default class AtAGlance extends Component {
    constructor(state) {
        super(state);
        this.state = {
            hover: false,
            hoverIndex: ''
        };
        this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
        this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
    }
    onMouseEnterHandler(index) {
        // const selectorPositionClient = e.target.getBoundingClientRect();
        // const selectorPosition = selectorPositionClient.bottom - selectorPositionClient.y;
        // console.log('selectorPosition ' + selectorPosition)
        this.setState({
            hover: true,
            hoverIndex: index
        });
    }
    onMouseLeaveHandler(index) {
        this.setState({
            hover: false,
            hoverIndex: index
        });
    }
    render() {
        const marketAnalysisCopy = this.props.marketAnalysis && this.props.marketAnalysis;
        const foodserviceLocations = marketAnalysisCopy && marketAnalysisCopy.foodservice_locations;
        const people = marketAnalysisCopy && marketAnalysisCopy.people;
        const restaurants = marketAnalysisCopy && marketAnalysisCopy.restaurants;
        const inOperatorSpend = marketAnalysisCopy && marketAnalysisCopy.in_operator_spend;
        const charts = marketAnalysisCopy && marketAnalysisCopy.charts;
        return (
            <Fragment>
                <div className="ts-mod-header ts-mod-header--glance"><span className="ts-mod-header__title">AT A GLANCE</span></div>

                <div className="ts-block__content-section border-bottom">
                    <ul className="summary-analysis ">
                        <li className="summary-analysis__item">
                            <div className="summary-analysis__data summary-analysis__data--blue">{people}</div>
                            PEOPLE
                        </li>
                        <li className="summary-analysis__item">
                            <div className="summary-analysis__data summary-analysis__data--green">{foodserviceLocations}</div>
                            TOTAL FOODSERVICE<br />LOCATIONS
                        </li>
                        <li className="summary-analysis__item">
                            <div className="summary-analysis__data summary-analysis__data--dark-green">{restaurants}</div>
                            RESTAURANTS
                        </li>
                        <li className="summary-analysis__item">
                            <div className="summary-analysis__data summary-analysis__data--red">{inOperatorSpend}</div>
                            IN OPERATOR SPEND
                        </li>
                    </ul>
                </div>

                <div className="ts-block__content-section">
                    <div className="summary-chart clearfix">
                        {charts && charts.map((item, index) => {
                            const itemHeight = item.height > 94 ? 94 : item.height;
                            return <div className="summary-chart__item" key={index}>
                                <div className="summary-chart__title">{item.label}</div>
                                <div className="summary-chart__progress-block">
                                    <div className="summary-chart__progress" style={{height: `${item.height}%`}}></div>
                                    <div className="summary-chart__label"
                                         style={{bottom: `${itemHeight}%`}}
                                         onMouseEnter={this.onMouseEnterHandler.bind(this, index)}
                                         onMouseLeave={this.onMouseLeaveHandler.bind(this, index)}
                                    >
                                        {item.chart_label}
                                        <div className="summary-chart__label-inner">
                                        <div className={("tip-body tip-body--dark is-at-glance is-center") + (this.state.hover && this.state.hoverIndex === index ? ' is-active' : '')}>
                                            <div className="tip-body__block-data">
                                                {charts && item.chart_details.map((chart, i) => {
                                                    return <div className="tip-body__row" key={i}>
                                                        <div className="tip-body__col-l">{chart.label}:</div>
                                                        <div className="tip-body__col-r">{chart.value}</div>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div className="summary-chart__data">{item.value}</div>
                                <div className="summary-chart__caption">{item.description}</div>
                            </div>
                        })}
                    </div>
                </div>
            </Fragment>
        )
    }
}