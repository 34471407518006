import React, { Component } from 'react';
import './style.scss';
import LineChart from 'components/main/line-chart';


export default class WorkingPopulation extends Component {

    render() {
        const {metroId, WorkingPopulation} = this.props;
        const chartData = WorkingPopulation && WorkingPopulation.local_industries;
        const totalsData = WorkingPopulation && WorkingPopulation.totals;
        const averageEarningData = WorkingPopulation && WorkingPopulation.average_earning;
        return (
            <div className="ts-block__content-modul">

                <div className="ts-section-header">
                    <div className="ts-section-header__title ts-section-header__col">
                        <span className="ts-section-header--text-uppercase">BUSINESS / DAYTIME WORKING POPULATION</span>
                    </div>
                </div>

                <div className="working-population ts-block__content-section ts-block__content-section--working-population">
                    <div className="working-population__col working-population__col-count">
                        <div className="working-population__inner">
                            <div className="sticker in-groups">
                                <div className="sticker__count">{WorkingPopulation && totalsData.businesses}</div>
                                <div className="sticker__name">businesses</div>
                            </div>
                            <div className="sticker in-groups">
                                <div className="sticker__count">{WorkingPopulation && totalsData.employees}</div>
                                <div className="sticker__name">employees</div>
                            </div>
                            <div className="sticker in-groups">
                                <div className="sticker__count">{WorkingPopulation && totalsData.payroll}</div>
                                <div className="sticker__name">in payroll</div>
                            </div>
                        </div>
                    </div>
                    <div className="working-population__col working-population__col-chart">
                        <div className="working-population__inner">
                            <div className="ts-mod-header">LOCAL INDUSTRIES <br/><span className="ts-mod-header__caption">(% of workforce)</span></div>
                            <LineChart
                                metroId={metroId}
                                chartData={chartData}
                                total={false}
                            />
                        </div>
                    </div>
                    <div className="working-population__col working-population__col-count">
                        <div className="working-population__inner">
                            <div className="ts-mod-header">Average Earnings<br/>per Worker</div>
                            <div className="sticker in-groups">
                                <div className="sticker__count">${WorkingPopulation && averageEarningData.current}</div>
                                <div className="sticker__name">Current area</div>
                            </div>
                            <div className="sticker in-groups">
                                <div className="sticker__count">${WorkingPopulation && averageEarningData.total}</div>
                                <div className="sticker__name">Total US</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}