import React, { Component, Fragment } from 'react';
import './style.scss';

export default class LanguageSpoken extends Component {

    render() {
        const languagesCopy = this.props.marketConsumer && this.props.marketConsumer.languages;
        return (
            <Fragment>
                <div className="ts-mod-header">LANGUAGES SPOKEN NEARBY</div>
                <div className="spoken-language">
                    <div className="spoken-language__row clearfix">
                        {languagesCopy && languagesCopy.map((result, index) => {
                            return (<div className="spoken-language__col" key={index}>
                                <div className="spoken-language__item clearfix">
                                    <div className="spoken-language__data spoken-language__data--current">{result.percent}<span className="spoken-language__percent">%</span></div>
                                    <div className="spoken-language__name">
                                        <span className="spoken-language__name-text">{result.label}</span>
                                    </div>
                                </div>
                            </div>);
                        })}
                    </div>
                </div>
            </Fragment>
        )
    }
}