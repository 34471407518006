import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Page404 extends Component {

    render() {
        return (
            <div className="error-content">
                <div className="error-content__inner">
                    <div className="error-content__title">Page not found</div>
                    <div className="error-content__error-name">404</div>
                    <div className="error-content__caption">You’re looking for something that doesn’t actually exist...</div>
                    <Link to="/" className="button blue">Homepage</Link>
                </div>
            </div>
        )
    }
}