import {
    LANDING_ACCESS_CREDENTIALS,
    LANDING_ACCESS_NAFEM,
    LANDING_ACCESS_SNAP,
    LANDING_ACCESS_FIREFLY,
    LANDING_ACCESS_EMAIL,
    LANDING_ACCESS_IS_FIRST,
} from './landingActionTypes';
import Http from 'helpers/http';
import { API_ACCESS, API_IS_FIRST } from "../../core/constants";

export function getAccess() {
    return (dispatch) => {
        Http.get(`${API_IS_FIRST}`).then((data) => {
            dispatch(accessIsFirst(data));
        });
        Http.get(`${API_ACCESS}/get-credentials`).then((data) => {
            dispatch(accessCredentials(data));
        });
        Http.get(`${API_ACCESS}/has-nafem-access`).then((data) => {
            dispatch(accessNafem(data));
        });
        Http.get(`${API_ACCESS}/has-snap-access`).then((data) => {
            dispatch(accessSnap(data));
        });
        Http.get(`${API_ACCESS}/has-firefly-access`).then((data) => {
            dispatch(accessFirefly(data));
        });
        Http.get(`${API_ACCESS}/get-email`).then((data) => {
            dispatch(accessEmail(data));
        });
    };
}

function accessCredentials(data) {
    return {
        type: LANDING_ACCESS_CREDENTIALS,
        data
    }
}
function accessNafem(data) {
    return {
        type: LANDING_ACCESS_NAFEM,
        data
    }
}
function accessSnap(data) {
    return {
        type: LANDING_ACCESS_SNAP,
        data
    }
}
function accessFirefly(data) {
    return {
        type: LANDING_ACCESS_FIREFLY,
        data
    }
}
function accessEmail(data) {
    return {
        type: LANDING_ACCESS_EMAIL,
        data
    }
}
function accessIsFirst(data) {
    return {
        type: LANDING_ACCESS_IS_FIRST,
        data
    }
}