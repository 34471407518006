import React, { Component, Fragment } from 'react';
import Icon from 'components/main/svg-component';

export default class ModalEmailSuccess extends Component {
    constructor(state){
        super(state);
        this.state = {
            modalVisible: this.props.modalVisible,
        };
        this.closeModal = this.closeModal.bind(this);
    }
    closeModal() {
        this.props.hideModalLogin();
    }
    render() {
        return (
            <Fragment>
                <div
                    className={"overlay" + (this.props.modalVisible ? ' is-visible' : '')}
                    onClick={this.closeModal.bind(this)}
                ></div>
                {/*Login*/}

                <div className={"login-modal" + (this.props.modalVisible ? ' is-visible' : '')}>
                    <div className={"login-modal__body email-success"}>
                        <span className="login-modal__close" onClick={this.closeModal.bind(this)}><Icon iconClassName='remove' /></span>
                        <div className="email-success__icon-holder">
                            <Icon iconClassName='email' />
                        </div>
                        <div className="email-success__text">
                            Thank you, your request has been sent.<br/>
                            Charlie will reply as soon as possible.
                        </div>
                    </div>
                </div>
                {/*//Login*/}
            </Fragment>
        )
    }
}