import React, { Component } from 'react';

import Icon from 'components/main/svg-component';
import { Scrollbars } from 'react-custom-scrollbars';

export default class FilterTable extends Component {

    constructor(state) {
        super(state);
        this.state = {
            filterActive: false,
            inputValue: '',
            dropDownIdActive: ''
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }
    componentDidUpdate() {
        // if(this.props.autoSuggestValue === '' && this.state.dropDownIdActive !== '') {
        //     this.setState({
        //         dropDownIdActive: '',
        //     });
        // }
    }
    filterButtonAction() {
        this.setState({
            filterActive: !this.state.filterActive
        });
        document.addEventListener('click', this.handleOutsideClick, false);
    }
    handleClick() {
        if (!this.state.filterActive) {
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
            this.setState({
                filterActive: false,
            });
        }
    }
    handleOutsideClick(e) {
        if (this.node.contains(e.target)) {
            return;
        }
        this.handleClick();
    }
    itemClick(sortKey, id) {
        this.handleClick(this);
        this.props.filterSend(sortKey, id);
        this.setState({
            dropDownIdActive: id,
        });
    }
    itemCuisinesClick(sortKey, id) {
        this.handleClick(this);
        this.props.filterCuisinesSend(sortKey, id);
        this.setState({
            dropDownIdActive: id,
        });
    }
    //tableActionSearch
    render() {
        const {
            filterSearch,
            filterAutoSuggest,
            filterList,
            sortKey,
            filterSend,
            autosuggestListSend,
            autosuggestList,
            filterValue,
            autoSuggestValue,
            tableSearchValue,
            metroId,
            segmentId,
            cuisineId
        } = this.props;
        const _this = this;
        const isIE = /*@cc_on!@*/false || !!document.documentMode;
        return (
            <div className={"table-filter" + (this.state.filterActive ? ' is-active' : '')} ref={node => {this.node = node;}}>
                <span className="table-filter__button"
                      onClick={this.filterButtonAction.bind(this)}
                ><Icon iconClassName='arr-light' /></span>
                {filterSearch.includes(sortKey) ?
                    <div className="table-search table-search--long">
                        <div className="table-search__input-wrapper">
                            <input type="text"
                                   className="table-search__input"
                                   onChange={this.props.tableActionSearch.bind(this, sortKey)}
                                   value={tableSearchValue}
                                   placeholder="find"
                            />
                        </div>
                    </div>
                    : null}
                {filterAutoSuggest.includes(sortKey) ?
                    <div className="table-search table-search--long">
                        <div className="table-search__input-wrapper">
                            <input type="text"
                                   className="table-search__input"
                                   value={autoSuggestValue}
                                   onChange={autosuggestListSend.bind(this, sortKey)}
                                   placeholder="find"
                            />
                        </div>
                        {autosuggestList && Object.keys(autosuggestList).length > 0 ? (
                                <div className={"filter-list-wrapper" + (isIE ? ' is-ie' : '')}>
                                    <Scrollbars autoHeight autoHeightMax={280}>
                                        <ul className="filter-list">
                                            {autosuggestList.map((item, index) => {
                                                return <li
                                                    key={index}
                                                    className={"filter-list__item" +
                                                        (metroId === item.id ? ' is-active' : '')
                                                    }
                                                    onClick={_this.itemClick.bind(this, sortKey, item.id)}
                                                >{item.name}</li>
                                            })}
                                        </ul>
                                    </Scrollbars>
                                </div>
                            ) : null
                        }

                    </div>
                    : null}
                {filterList.includes(sortKey) ?

                    <div className={"table-search" + (isIE ? ' is-ie' : '')}>
                        {this.props[`list_${sortKey}`] && Object.keys(this.props[`list_${sortKey}`]).length > 0 ? (
                                <Scrollbars autoHeight autoHeightMax={320}>
                                    <ul className="filter-list">
                                        {this.props[`list_${sortKey}`] && this.props[`list_${sortKey}`].map((item, index) => {
                                            return <li key={index}
                                                       className={"filter-list__item" + (this.props[`${sortKey}Id`] === item.id ? ' is-active' : '')}
                                                       onClick={_this.itemCuisinesClick.bind(this, sortKey, item.id)}
                                            >{item.name}</li>
                                        })}
                                    </ul>
                                </Scrollbars>
                            ) : null
                        }

                    </div>
                    : null}
            </div>
        )
    }
}