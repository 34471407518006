import React, { Component, Fragment } from 'react';
import './style.scss';

export default class LineChart extends Component {

    render() {
        const {metroId, chartData, total} = this.props;
        return (
            <Fragment>

                <div className="line-chart">
                    <div className="line-chart__col--full">

                        {total &&
                            <div className={'line-chart-header line-chart-header--area' + (metroId !== '0' ? ' line-chart-header--bordered' : '')}>
                                {metroId !== '0' &&
                                <span className="line-chart-header__title line-chart-header__title--area">Current area</span>
                                }
                            </div>
                        }



                        <div className="line-chart__col-flex">
                            <div className="line-chart__col line-chart__col--item t-right">
                                {chartData && chartData.map((result, index) => {
                                    return (<div className="line-chart__label line-chart__row" key={index}>
                                        <span className="line-chart__label-name">{result.label}</span>
                                    </div>);
                                })}
                            </div>

                            <div className="line-chart__col line-chart__col--item line-chart__col--full">
                                {chartData && chartData.map((result, index) => {
                                    const widthChart = result.percent === undefined ? result.total_percent : result.percent;
                                    return (<div className="line-chart__row" key={index}>
                                        <span className="line-chart__progress-block">
                                            <span className="line-chart__progress" style={{width: widthChart + '%'}}></span>
                                        </span>
                                    </div>);
                                })}
                            </div>

                            {metroId !== '0' &&
                            <div className="line-chart__col line-chart__col--item t-right">
                                {chartData && chartData.map((result, index) => {
                                    return (<div className="line-chart__row" key={index}>
                                        <span className="line-chart__val">{result.percent}%</span>
                                    </div>);
                                })}
                            </div>
                            }
                        </div>

                    </div>
                    {total &&
                    <div className="line-chart__col line-chart__col--item">
                        <div className="line-chart-header">
                            <span className="line-chart-header__title">Total US</span>
                        </div>
                        <div className="line-chart__col t-right">
                            {chartData && chartData.map((result, index) => {
                                return (<div className="line-chart__row" key={index}>
                                    <span className="line-chart__difference">{result.total_percent}%</span>
                                </div>);
                            })}
                        </div>

                    </div>
                    }

                </div>
            </Fragment>
        )
    }
}