import React, { Component, Fragment, PropTypes } from 'react';
import { connect, match } from 'react-redux';
import './style.scss';

import ChainArea from 'components/metro/operators/chains-area';



export default class OperatorsIndex extends Component {
    constructor(state) {
        super(state);
        this.state = {};
        this.actionModal = this.actionModal.bind(this);
    }
    actionModal(id) {
        this.props.changeActiveTab(id)
    }
    render() {
        return (
            <Fragment>
                <ChainArea
                    metroId={this.props.metroId}
                    actionModal={this.actionModal}
                    tabId={this.props.tabId}
                    type={this.props.type}
                />
            </Fragment>
        )
    }
}