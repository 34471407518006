import React, { Component, Fragment } from 'react';
import { connect, match } from 'react-redux';
import './style.scss';
import SwitchFilter from 'components/main/switch-filter';
import Table from 'components/main/table-general';

import {
    getOverviewData,
    updateMarketCuisinesData
} from 'containers/Metro/metroActions';

class MenuTypes extends Component {
    constructor(state) {
        super(state);
        this.state = {
            typeSegment: 'chain',
            cuisineType: 'primary',
            cuisineSort: 'name',
            cuisineSortType: 'ask',
        };
        this.sortingCuisineTable = this.sortingCuisineTable.bind(this);
    }
    componentDidMount() {
        const metroId = this.props.metroId;
        const type = this.props.type;
        this.props.getOverviewData(
            metroId,
            type,
            this.state.typeSegment,
            this.state.cuisineType,
            this.state.cuisineSort,
            this.state.cuisineSortType
        );
        window.scrollTo(0, 0);
    }
    changeMenuTypes(value, group) {
        const metroId = this.props.metroId;
        const type = this.props.type;
        this.setState({[group]: value}, function () {
            this.props.updateMarketCuisinesData(
                metroId,
                type,
                this.state.typeSegment,
                this.state.cuisineType,
                this.state.cuisineSort,
                this.state.cuisineSortType
            );
        });
    }

    sortMenuTypes(sort, sortType) {
        this.setState({
            cuisineSort: sort,
            cuisineSortType: sortType,
        }, function () {
            const metroId = this.props.metroId;
            const type = this.props.type;
            this.props.updateMarketCuisinesData(
                metroId,
                type,
                this.state.typeSegment,
                this.state.cuisineType,
                this.state.cuisineSort,
                this.state.cuisineSortType
            );
        });
    }
    sortingCuisineTable(colKey) {
        const newChainAreaSortType = colKey !== this.state.cuisineSort ? 'asc' : this.state.cuisineSortType === 'desc' ? 'asc' : 'desc';
        this.sortMenuTypes(colKey, newChainAreaSortType)
    }
    render() {
        const {metroCurrentId, metroId} = this.props;
        const marketCuisinesCopy = this.props.marketCuisines && this.props.marketCuisines.table;
        return (
            <Fragment>
                <div className="ts-section-header">
                    <div className="ts-section-header__title ts-section-header__col">
                        <span className="ts-section-header--text-uppercase">Menu Types</span>
                    </div>
                    <div className="ts-filter ts-section-header__col">
                        <div className="">
                            <span className="ts-filter__label">Show by</span>
                            <SwitchFilter
                                switchData={typeSegment}
                                group="typeSegment"
                                prefixId="typeSegment"
                                checked={this.state.typeSegment}
                                action={this.changeMenuTypes.bind(this)}
                            />
                            <SwitchFilter
                                switchData={cuisineType}
                                group="cuisineType"
                                prefixId="cuisineType"
                                checked={this.state.cuisineType}
                                action={this.changeMenuTypes.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                
                <div className="ts-block__content-section relative">
                    <Table
                        tableData={metroCurrentId === metroId ? marketCuisinesCopy : null}
                        tableClass=""
                        firstColClass=""
                        colClass="go-table__th--key-markets"
                        hover={false}
                        sortingTableAction={this.sortingCuisineTable.bind(this)}
                        tableSort={this.state.cuisineSort}
                        tableSortType={this.state.cuisineSortType}
                    />
                </div>
            </Fragment>
        )
    }
}

const typeSegment = [
    {
        title: 'Type',
        value: 'chain'
    },
    {
        title: 'Segments',
        value: 'segment'
    }
];

const cuisineType = [
    {
        title: 'Basic',
        value: 'primary'
    },
    {
        title: 'Detailed',
        value: 'secondary'
    }
];

const mapStateToProps = state => ({
    metroCurrentId: state.metropageReducer.metroCurrentId,
    marketCuisines: state.metroOverviewReducer.marketCuisines,
    mapInitialBorders: state.metroOverviewReducer.mapInitialBorders,
});

const mapDispatchToProps = dispatch => ({
    getOverviewData: (
        propId,
        type,
        typeSegment,
        cuisineType,
        sort,
        sortType
    ) => dispatch(getOverviewData(propId, type, typeSegment, cuisineType, sort, sortType)),
    updateMarketCuisinesData: (
        propId,
        type,
        typeSegment,
        cuisineType,
        sort,
        sortType
    ) => dispatch(updateMarketCuisinesData(propId, type, typeSegment, cuisineType, sort, sortType)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MenuTypes);