import React, { Component, Fragment } from 'react';
import { connect, match } from 'react-redux';
import './style.scss';
import Map from 'components/main/map';
import Table from 'components/main/table-general';

import { Scrollbars } from 'react-custom-scrollbars';

export default class MarketStructure extends Component {
    constructor(state) {
        super(state);
        this.state = {
            marketStructureActive: '1',
        };
        this.checkAction = this.checkAction.bind(this);
    }
    checkAction(id) {
        this.setState({
            marketStructureActive: id
        })
    }
    render() {
        const regionType = this.props.type === 'census' ? 'census_region_id' : this.props.type;
        return (
            <Fragment>
                <div className="ts-mod-header ts-mod-header--border"><span className="ts-mod-header__title">Market Structure</span></div>

                <div className="ts-block__content-section ts-block__content-section--short relative">

                    <div className="container-row">
                        <div className="container-row__col">
                            <div className="container-scroll-out">
                                <Scrollbars
                                    autoHeight
                                    autoHeightMax={`(500 / 1920) * 100vw`}
                                    //autoHeightMax={500}
                                >
                                    <div className="container-scroll-inner">
                                        <Table
                                            tableData={this.props.marketStructure}
                                            tableClass=""
                                            firstColClass="is-market-structure"
                                            colClass="go-table__th--key-markets"
                                            hover={false}
                                            segmentsAction={true}
                                            inputName="market_structure"
                                            checkAction={this.checkAction.bind(this)}
                                            marketStructureActive={this.state.marketStructureActive}
                                        />
                                    </div>
                                </Scrollbars>
                            </div>
                        </div>
                        <div className="container-row__col">
                            <div
                                style={{width: '100%', height: `(500 / 1920) * 100vw`}}
                                //style={{width: '100%', height: '500px'}}
                            >

                                <Map
                                    marketStructureActive={this.state.marketStructureActive}
                                    metroId={this.props.metroId}
                                    regionType={regionType}
                                />
                                {/*<a href="#" className="link-download-map">Download image</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}