import {
    SEGMENTS_GET_LIST,
    SEGMENTS_GET_DATA,
    SEGMENTS_GET_NOTABLE_OPERATORS,
    SEGMENTS_GET_NOTABLE_OPERATORS_TABS
} from './segmentsActionTypes';

const segmentsState = {
    segmentsList: [],
    segmentsData: [],
    notableOperators: [],
    notableOperatorsTabs: []
};

export default function segmentsReducer(state = segmentsState, action) {
    switch (action.type) {
        case SEGMENTS_GET_LIST: {
            return {
                ...state,
                segmentsList: action.data,
            }
        }
        case SEGMENTS_GET_DATA: {
            return {
                ...state,
                segmentsData: action.data,
            }
        }
        case SEGMENTS_GET_NOTABLE_OPERATORS: {
            return {
                ...state,
                notableOperators: action.data,
            }
        }
        case SEGMENTS_GET_NOTABLE_OPERATORS_TABS: {
            return {
                ...state,
                notableOperatorsTabs: action.data,
            }
        }
        default: {
            return state;
        }
    }
}
