import { createSelector } from 'reselect';

const selectedFilters = state => state.chainpageReducer.selectedFilters;
const selectedTrafficFilters = state => state.chainTrafficReducer.selectedTrafficFilters;
const periodSelector = state => state.chainpageReducer.periodSelector;
const metroAreas = state => state.chainpageReducer.metroAreas;
const trafficSummarySelector = state => state.chainTrafficReducer.trafficSummaryChart;
const historyPeriodSelector = state => state.chainTrafficReducer.historyPeriodSelector;

export const currentPeriodSelector = createSelector(
    [periodSelector, selectedFilters],
    (periodSelector, filter) => {
        let selectedPeriod = null;
        //if (filter.selectedPeriodId) {
            selectedPeriod = {
                ...periodSelector.find(period => period.id === filter.selectedPeriodId)
            }
        //}
        return selectedPeriod;
    }
);

export const currentMetroSelector = createSelector(
    [metroAreas, selectedFilters],
    (metrosData, filter) => {
        let selectedMetro = null;
        if (filter.selectedAreaId) {
            selectedMetro = {
                ...metrosData.find(metro => metro.id === filter.selectedAreaId)
            }
        }
        return selectedMetro;
    }
);

export const currentHistoryPeriodSelector = createSelector(
    [historyPeriodSelector, selectedTrafficFilters],
    (historyPeriodData, filter) => {
        const selectedHistory = {
            ...historyPeriodData.find(history=> history.id === filter.historyPeriodSelectorId)
        };
        return selectedHistory;
    }
);

export const trafficSummaryChartDataSelector = createSelector(
    trafficSummarySelector,
    points => {
        if (points.length) {
            return {
                title: {
                    text: 'TRAFFIC SUMMARY CHART'
                },
                chart: {
                    margin: [10, 100, 180, 100],
                    height: 800
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: points.map(chain => points.name),
                    tickWidth: 0,
                    lineWidth: 0,
                    min: 0,
                    max: points.length,
                    showLastLabel: true,
                    labels: {
                        autoRotation: [-60],
                        style: {
                            color: '#9E9E9E',
                            fontSize: '13px',
                            fontFamily: 'Josefin Sans'
                        },
                        formatter: function () {
                            return Number.isInteger(this.value) ? '' : this.value;
                        }

                    }
                },
                yAxis: {
                    min: Math.min(...points.map(chain => chain.share), ...points.map(chain => chain.pcya)),
                    max: Math.max(...points.map(chain => chain.share), ...points.map(chain => chain.pcya)),
                    gridLineWidth: 0,
                    title: {
                        text: null
                    },
                    plotBands: [{
                        from: -1000,
                        to: 0,
                        color: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: [
                                [0, '#fbdddd'],
                                [1, '#fff']
                            ]
                        }

                    }, {
                        from: 0,
                        to: 1000,
                        color: {
                            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                            stops: [
                                [0, '#fff'],
                                [1, '#e1f3d8']
                            ]
                        }
                    }],
                    labels: {
                        enabled: false
                    }
                },
                series: [{
                    type: 'column',
                    name: 'SHARE',
                    color: '#4AD3FF',
                    negativeColor: '#BDBDBD',
                    borderWidth: 0,
                    getExtremesFromAll: true,
                    data: points.map(chain => chain.share)
                }, {
                    type: 'line',
                    name: 'PCYA',
                    data: points.map(chain => chain.pcya),
                    color: '#7CCB54',
                    negativeColor: '#EB5757',
                    lineWidth: 4,
                    shadow: {
                        color: '#000',
                        offsetX: 0,
                        offsetY: 4,
                        opacity: 0.3,
                        width: 4
                    },
                    marker: {
                        enabled: false
                    }
                }],
                legend: {
                    enabled: false
                },
            }
        }
        return null;
    }
);